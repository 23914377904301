import { format } from 'date-fns';
import { enUS, pt, es, fr, ja } from 'date-fns/locale';
import { Button } from '@components/Button';
import { Icon } from '@components/Icon';
import { Popover } from '@components/Popover';
import { DateFormat, Locales } from '@components/DatePicker';
import { FilterOptions } from '../FilterOptions';
import { FilterOption, FilterPropOptions } from '../../types';
import { useFilter } from '../../context';
import { buttonsContainer, closeButton, closeButtonContainer, contentButtonsContainer, triggerButton } from '../styles';

type FilterSavedProps = {
    filter: FilterOption;
    index: number;
    isCurrentPopoverOpen: boolean;
    isApplyDisabled: boolean;
    options: FilterPropOptions;
};

const getDateLocale = (locale: Locales = 'en') =>
    ({
        en: enUS,
        pt,
        es,
        fr,
        jp: ja,
        ja,
    })[locale] || enUS;

const getPopoverValuesSelected = (filter: FilterOption, options: FilterPropOptions): string => {
    const dateLocale = options?.resources.customDateFnsLocale ?? getDateLocale(options.locale);

    if (filter.type === 'empty' || filter.type === 'option') {
        return filter.label;
    }

    if (filter.type === 'date') {
        return format(new Date(filter.value ?? ''), DateFormat.longLocalizedDate, { locale: dateLocale });
    }

    if (filter.type === 'select') {
        const optionSelected = filter.options.find((option) => option.value === filter.value);

        if (optionSelected?.option) {
            const optionValue = getPopoverValuesSelected(optionSelected.option, options);

            return `${optionSelected.label} ${optionValue}`;
        }

        return optionSelected?.label ?? '';
    }

    if (filter.type === 'radio') {
        if (filter.options?.length) {
            const optionSelected = filter.options.find((option) => option.value === filter.value);

            if (optionSelected?.options?.length) {
                const optionsEnabled = optionSelected.options.filter((option) => option.editable);

                if (optionsEnabled.length > 0) {
                    const insideOptionSelected = optionSelected.options.map((option) =>
                        getPopoverValuesSelected(option as FilterOption, options),
                    );

                    return `${optionSelected.label} ${insideOptionSelected.join(' ')}`;
                }
            }

            return getPopoverValuesSelected(optionSelected as FilterOption, options);
        }
    }

    if (filter.type === 'checkbox') {
        if (filter.options?.length) {
            const optionsSelected = filter.options.filter((option) => option.checked);

            const optionsSelectedText = optionsSelected.map((optionSelected) => {
                if (optionSelected?.options?.length) {
                    const optionsEnabled = optionSelected.options.filter((option) => option.editable);

                    if (optionsEnabled.length > 0) {
                        const insideOptionSelected = optionsEnabled.map((option) =>
                            getPopoverValuesSelected(option as FilterOption, options),
                        );

                        return `${optionSelected.label} ${insideOptionSelected.join(' ')}`;
                    }
                }

                return getPopoverValuesSelected(optionSelected as FilterOption, options);
            });

            return optionsSelectedText.join(', ').trim().replace(/,$/, '');
        }
    }

    return filter.value ?? '';
};

const getFullPopoverLabel = (filter: FilterOption, options: FilterPropOptions) => {
    if (filter.type === 'empty') {
        return (
            <>
                {filter.groupLabel} <span className="font-semibold">{filter.label}</span>
            </>
        );
    }

    if (filter.type === 'date') {
        return (
            <>
                {filter.label} <span className="font-semibold">{getPopoverValuesSelected(filter, options)}</span>
            </>
        );
    }

    if (filter.type === 'select') {
        return (
            <>
                {filter.label} <span className="font-semibold">{getPopoverValuesSelected(filter, options)}</span>
            </>
        );
    }

    if (filter.type === 'radio' || filter.type === 'checkbox') {
        const valuesSelectedSplitted = getPopoverValuesSelected(filter, options).split(',');

        const totalOfValuesSelected = valuesSelectedSplitted.length - 1;

        return (
            <>
                {filter.label}{' '}
                {valuesSelectedSplitted.map((text, index) => (
                    <span key={text} className="font-semibold">
                        {text}
                        {totalOfValuesSelected !== index && <span className="font-regular">, </span>}
                    </span>
                ))}
            </>
        );
    }

    return (
        <>
            {filter.label} <span className="font-semibold">{filter.value ?? ''}</span>
        </>
    );
};

export function FilterSaved({ filter, index, isCurrentPopoverOpen, isApplyDisabled, options }: FilterSavedProps) {
    const { resources } = options;

    const {
        containerRef,
        filterState: { selectedConfig },
        handleRemoveFilterApplied,
        handleFiltersAppliedBeingChanged,
        handleConfirmFilterChanges,
        handleSelectedFilterChanges,
    } = useFilter('FilterSaved');

    const isButtonDisabled = !filter.editable;

    const filterToBeUsedOnFilterOptions = isCurrentPopoverOpen ? selectedConfig : filter;

    const handlePopoverOpen = (open: boolean) => {
        if (filter.type !== 'empty') {
            handleFiltersAppliedBeingChanged(open, index);
        }
    };

    return (
        <Popover.Root
            key={filter.label}
            open={filter.type === 'empty' ? false : isCurrentPopoverOpen}
            onOpenChange={handlePopoverOpen}
        >
            <div data-disabled={isButtonDisabled} className={buttonsContainer()}>
                <Popover.Trigger asChild disabled={isButtonDisabled}>
                    <button className={triggerButton()}>
                        <Icon
                            type="bytalk"
                            iconName="arrow-down"
                            className="flex size-4 items-center justify-center p-1"
                        />
                        {getFullPopoverLabel(filter, options)}
                    </button>
                </Popover.Trigger>
                <div className={closeButtonContainer()}>
                    <button
                        disabled={isButtonDisabled}
                        onClick={() => handleRemoveFilterApplied(index)}
                        className={closeButton()}
                    >
                        <Icon type="bytalk" iconName="close-details" label={resources.removeFilter} />
                    </button>
                </div>
            </div>
            <Popover.Content container={containerRef.current} styleGuide="new">
                <FilterOptions
                    filterOption={filterToBeUsedOnFilterOptions}
                    onChange={handleSelectedFilterChanges}
                    options={options}
                />
                <div className={contentButtonsContainer()}>
                    <Popover.CloseButton asChild>
                        <Button styleGuide="new" variant="secondary">
                            {resources.cancel}
                        </Button>
                    </Popover.CloseButton>
                    <Button
                        styleGuide="new"
                        variant="tertiary"
                        onClick={() => handleConfirmFilterChanges(index)}
                        disabled={isApplyDisabled}
                    >
                        {resources.apply}
                    </Button>
                </div>
            </Popover.Content>
        </Popover.Root>
    );
}
