import { ComponentPropsWithRef, forwardRef, HTMLAttributes, useEffect, useImperativeHandle, useRef } from 'react';
import { isUndefined, sortByProperty } from '@sidetalk/helpers';
import { cn } from '@helpers/index';
import { Icon } from '../Icon';
import { Select, type GroupBase, type SelectOption } from '../Select';
import { FilterSaved } from './components/FilterSaved';
import { FilterToSave } from './components/FilterToSave';
import { FilterContextProvider, useFilter } from './context';
import { FilterData, FilterOption, FilterPropOptions } from './types';
import { customAddFilterSelectStyles } from './styles';

export type FilterProps = {
    filterData: FilterData;
    savedFilters?: FilterOption[];
    onFilterChange: (filterOptions: FilterOption[]) => void;
    options: FilterPropOptions;
};

const getFilterOptions = (arrayOfFilters: [string, FilterOption[]][]) =>
    arrayOfFilters.reduce<GroupBase<SelectOption>[]>((acc, [label, filterOptions]) => {
        const newAcc = [...acc];

        const newOption: GroupBase<SelectOption> = {
            label,
            options: filterOptions
                .map((filterOption) => ({
                    label: filterOption.label,
                    value: filterOption.id,
                    isDisabled: !filterOption.editable,
                    order: filterOption.order,
                }))
                .sort(sortByProperty('order')),
        };

        return [...newAcc, newOption];
    }, []);

const checkIfApplyButtonIsDisabled = (selectedConfig?: FilterOption) => {
    if (!selectedConfig) {
        return true;
    }

    if (!selectedConfig.editable) {
        return false;
    }

    if (selectedConfig.type === 'select') {
        const hasOptionSelected = !!selectedConfig.value;

        if (hasOptionSelected) {
            return !selectedConfig.options.every(({ option, value }): boolean => {
                if (option && value === selectedConfig.value) {
                    return !checkIfApplyButtonIsDisabled(option);
                }

                return true;
            });
        }

        return true;
    }

    if (selectedConfig.type === 'checkbox' || selectedConfig.type === 'radio') {
        const hasSomeOptionChecked = selectedConfig.checked;

        if (hasSomeOptionChecked) {
            return !selectedConfig.options.every(({ checked, options }): boolean => {
                if (checked && options) {
                    return options.every((option) => !checkIfApplyButtonIsDisabled(option));
                }

                return true;
            });
        }

        return true;
    }

    return selectedConfig.value ? selectedConfig.value.trim() === '' : true;
};

function Filters({ filterData, savedFilters, onFilterChange, options }: FilterProps) {
    const filterOptions = getFilterOptions(Object.entries(filterData));

    const isFirstRender = useRef(true);
    const onFilterChangeRef = useRef(onFilterChange);

    const {
        filterState: { filterSelected, selectedConfig, filtersApplied, indexAppliedFilterBeingChanged },
        handleSelectNewFilter,
        addSavedFilters,
    } = useFilter('Filters');

    useEffect(() => {
        onFilterChangeRef.current = onFilterChange;
    }, [onFilterChange]);

    useEffect(() => {
        const onFirstRenderCondition = isFirstRender.current && !!savedFilters && savedFilters.length > 0;
        const onNextRendersCondition = !isFirstRender.current && !!savedFilters;

        if (onFirstRenderCondition || onNextRendersCondition) {
            addSavedFilters(savedFilters);
        }
    }, [addSavedFilters, savedFilters]);

    useEffect(() => {
        if (!isFirstRender.current) {
            onFilterChangeRef.current(filtersApplied);
        }
    }, [filtersApplied]);

    useEffect(() => {
        const savedFiltersLength = savedFilters?.length ?? 0;

        if (filtersApplied.length >= savedFiltersLength && isFirstRender.current) {
            isFirstRender.current = false;
        }
    }, [filtersApplied, savedFilters]);

    const filtersAppliedLabel = filtersApplied.map((filterApplied) => filterApplied.id);

    const disabledFilterOptions = filterOptions.map((filter) => {
        const newOptions = filter.options.map((option) => {
            if (filtersAppliedLabel.includes(option.value)) {
                return {
                    ...option,
                    isDisabled: true,
                };
            }

            return option;
        });

        return {
            ...filter,
            options: newOptions,
        };
    });

    const isApplyDisabled = checkIfApplyButtonIsDisabled(selectedConfig);

    const showPopoverToApplyNewFilter = isUndefined(indexAppliedFilterBeingChanged) && selectedConfig;

    return (
        <>
            {filtersApplied?.map((filter, index) => {
                const isCurrentPopoverOpen = !isUndefined(indexAppliedFilterBeingChanged)
                    ? index === indexAppliedFilterBeingChanged
                    : false;

                return (
                    <FilterSaved
                        key={filter.id}
                        filter={filter}
                        index={index}
                        isCurrentPopoverOpen={isCurrentPopoverOpen}
                        isApplyDisabled={isApplyDisabled}
                        options={options}
                    />
                );
            })}
            {showPopoverToApplyNewFilter ? (
                <FilterToSave isApplyDisabled={isApplyDisabled} options={options} />
            ) : (
                <div className="relative h-9 *:w-fit">
                    <Select.Basic
                        aria-label={options.resources.addNewFilter}
                        noOptionsMessage={() => options.resources.noOptions ?? 'No options'}
                        placeholder={<Icon type="bytalk" iconName="adicionar" />}
                        options={disabledFilterOptions}
                        value={filterSelected}
                        onChange={(selectedFilter) => handleSelectNewFilter(selectedFilter, filterData)}
                        isDisabled={filterOptions.length === 0}
                        styleGuide="new"
                        styles={customAddFilterSelectStyles}
                    />
                </div>
            )}
        </>
    );
}

Filters.displayName = 'Filter.Filters';

const CustomRoot = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
    ({ className, ...rest }, forwardedRef) => {
        const { containerRef } = useFilter('CustomRoot');

        useImperativeHandle(forwardedRef, () => containerRef.current!, [containerRef]);

        return (
            <div
                ref={containerRef}
                className={cn(
                    [
                        'relative flex flex-wrap items-center gap-2',
                        '*:before:absolute *:before:-left-2 *:before:top-1/2',
                        '*:before:content-[""] first:*:before:content-none',
                        '*:before:h-px *:before:w-2 *:before:bg-gray-100',
                    ],
                    className,
                )}
                {...rest}
            />
        );
    },
);

function Root(props: HTMLAttributes<HTMLDivElement>) {
    return (
        <FilterContextProvider>
            <CustomRoot {...props} />
        </FilterContextProvider>
    );
}

Root.displayName = 'Filter.Root';

export type FilterRootProps = ComponentPropsWithRef<typeof Root>;

export const Filter = {
    Root,
    Filters,
};
