import { useMemo } from 'react';
import { DayPicker } from 'react-day-picker';
import { CalendarProps } from '@components/DatePicker/types';
import { getDatePickerStyles } from './styles';

export function Calendar(props: CalendarProps) {
    const classStyles = useMemo(() => getDatePickerStyles(), []);

    return <DayPicker showOutsideDays fixedWeeks classNames={classStyles} {...props} />;
}
