import {
    createContext,
    Dispatch,
    MutableRefObject,
    ReactNode,
    RefObject,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { isUndefined, isDate } from '@sidetalk/helpers';
import { closestTime } from './helpers';
import { UnitOfTime } from './types';

type StyleGuideType = 'coremedia' | 'new';

type TimeOnFocusRef = {
    available: UnitOfTime[];
    timesNodes: Record<UnitOfTime, Record<number, HTMLButtonElement | null>>;
};

type TimeState = {
    [x in UnitOfTime]?: number;
};

type DisabledTime = {
    [x in UnitOfTime]?: number[];
};

type TimePickerContextProps = {
    timeState: TimeState | null;
    currentTimeFocused?: UnitOfTime;
    allowEmpty: boolean;
    disabled: boolean;
    disabledHours: number[];
    disabledMinutes: number[];
    disabledSeconds: number[];
    styleGuide: StyleGuideType;
    contentRef: RefObject<HTMLDivElement>;
    closeButtonRef: RefObject<HTMLButtonElement>;
    timeOnFocusRef: MutableRefObject<TimeOnFocusRef>;
    setTimeState: Dispatch<React.SetStateAction<TimeState | null>>;
    registerTimes: (time: UnitOfTime) => () => void;
    handleTimeClick: (time: number, type: UnitOfTime) => void;
    handleClearTime: () => void;
    handleSelectCurrentTime: () => void;
    calculateDisabledTime?: (time: TimeState) => DisabledTime;
};

const TimePickerContext = createContext<TimePickerContextProps | null>(null);

export type TimePickerContextProviderProps = {
    defaultValue?: Date;
    value?: Date | null;
    allowEmpty?: boolean;
    disabled?: boolean;
    disabledHours?: number[];
    disabledMinutes?: number[];
    disabledSeconds?: number[];
    styleGuide?: StyleGuideType;
    onChangeTime?: (newTime: Date | null, newTimeFormatted: string) => void;
    calculateDisabledTime?: (time: TimeState) => DisabledTime;
    children: ReactNode;
};

export function TimePickerContextProvider({
    defaultValue,
    value,
    allowEmpty = false,
    disabled = false,
    disabledHours = [],
    disabledMinutes = [],
    disabledSeconds = [],
    calculateDisabledTime,
    styleGuide: initialStyleGuide = 'coremedia',
    onChangeTime,
    children,
}: TimePickerContextProviderProps) {
    const [timeState, setTimeState] = useState<TimeState | null>(() => {
        const initialDate = value ?? defaultValue;

        if (initialDate && isDate(initialDate)) {
            return {
                hour: initialDate.getHours(),
                minute: initialDate.getMinutes(),
                second: initialDate.getSeconds(),
            };
        }

        if (allowEmpty) {
            return null;
        }

        return {
            hour: 0,
            minute: 0,
            second: 0,
        };
    });
    const [currentTimeFocused, setCurrentTimeFocused] = useState<UnitOfTime>();
    const [styleGuide, setStyleGuide] = useState<StyleGuideType>(initialStyleGuide);

    const valueRef = useRef(value);
    const isInitialRender = useRef(true);
    const onChangeTimeRef = useRef(onChangeTime);
    const contentRef = useRef<HTMLDivElement>(null);
    const closeButtonRef = useRef<HTMLButtonElement>(null);
    const timeOnFocusRef = useRef<TimeOnFocusRef>({
        available: [],
        timesNodes: {
            hour: [],
            minute: [],
            second: [],
        },
    });

    const handleNextOnFocus = useCallback(
        (timeFocused?: UnitOfTime) => {
            const timesAvailableLength = timeOnFocusRef.current.available.length;

            if (timesAvailableLength >= 2) {
                setCurrentTimeFocused((oldState) => {
                    const timeFocusedIndex = timeOnFocusRef.current.available.findIndex(
                        (time) => (timeFocused ?? oldState) === time,
                    );

                    const newTimeOnFocus = timeOnFocusRef.current.available.at(
                        (timeFocusedIndex + 1) % timesAvailableLength,
                    )!;

                    /**
                     * Imperative focus during keydown is risky so we prevent React's batching updates
                     * to avoid potential bugs. See: https://github.com/facebook/react/issues/20332
                     */
                    setTimeout(
                        () =>
                            timeOnFocusRef.current.timesNodes[newTimeOnFocus][
                                timeState?.[newTimeOnFocus] ?? 0
                            ]?.focus(),
                        10,
                    );

                    return newTimeOnFocus;
                });
            }
        },
        [timeState],
    );

    const handlePrevOnFocus = useCallback(() => {
        const timesAvailableLength = timeOnFocusRef.current.available.length;

        if (timesAvailableLength >= 2) {
            setCurrentTimeFocused((oldState) => {
                const timeFocusedIndex = timeOnFocusRef.current.available.findIndex((time) => oldState === time);

                const newTimeOnFocus = timeOnFocusRef.current.available.at(
                    (timeFocusedIndex - 1) % timesAvailableLength,
                )!;

                timeOnFocusRef.current.timesNodes[newTimeOnFocus][timeState?.[newTimeOnFocus] ?? 0]?.focus();

                return newTimeOnFocus;
            });
        }
    }, [timeState]);

    const handleTimeClick = (time: number, type: UnitOfTime) => {
        setTimeState((oldState) =>
            oldState
                ? {
                      ...oldState,
                      [type]: time,
                  }
                : {
                      [type]: time,
                  },
        );

        const lastAvailableTime = timeOnFocusRef.current.available.at(-1);

        if (type !== lastAvailableTime) {
            handleNextOnFocus(type);
        } else {
            closeButtonRef.current?.focus();
            setCurrentTimeFocused(undefined);
        }
    };

    const handleSelectCurrentTime = useCallback(() => {
        const currentTime = new Date();

        const newTimeState = {
            hour: currentTime.getHours(),
            minute: currentTime.getMinutes(),
            second: currentTime.getSeconds(),
        };

        timeOnFocusRef.current.available.forEach((time) => {
            const timeToFocus = newTimeState[time];
            const timeNodes = Object.keys(timeOnFocusRef.current.timesNodes[time]).map(Number);

            const correctTimeWithSteps = closestTime(timeNodes, timeToFocus);

            timeOnFocusRef.current.timesNodes[time][correctTimeWithSteps]?.scrollIntoView({
                block: 'center',
            });

            newTimeState[time] = correctTimeWithSteps;
        });

        closeButtonRef.current?.focus();

        setTimeState(newTimeState);
    }, []);

    const handleClearTime = useCallback(() => {
        setTimeState(null);
    }, []);

    const registerTimes = useCallback((time: UnitOfTime) => {
        const alreadyHasTimeRegistered = timeOnFocusRef.current.available.includes(time);

        if (alreadyHasTimeRegistered) {
            throw new Error(`Cannot register ${time} twice`);
        }

        if (timeOnFocusRef.current.available.length === 0) {
            setCurrentTimeFocused(time);
        }

        timeOnFocusRef.current.available.push(time);

        return () => {
            timeOnFocusRef.current.available = timeOnFocusRef.current.available.filter(
                (availableTime) => availableTime !== time,
            );
        };
    }, []);

    const time = useMemo(() => {
        const timesRegistered = [];

        if (timeState) {
            if (!isUndefined(timeState.hour)) {
                const hour = String(timeState.hour).padStart(2, '0');
                timesRegistered.push(hour);
            }

            if (!isUndefined(timeState.minute)) {
                const minute = String(timeState.minute).padStart(2, '0');
                timesRegistered.push(minute);
            }

            if (!isUndefined(timeState.second)) {
                const second = String(timeState.second).padStart(2, '0');
                timesRegistered.push(second);
            }
        }

        return timesRegistered.join(':');
    }, [timeState]);

    useEffect(() => {
        setStyleGuide(initialStyleGuide);
    }, [initialStyleGuide]);

    useEffect(() => {
        onChangeTimeRef.current = onChangeTime;
    }, [onChangeTime]);

    useEffect(() => {
        const controller = new AbortController();
        const contentElement = contentRef.current;

        const handleTimeFocus = (type: 'next' | 'prev') => {
            const timeOnFocus = isUndefined(currentTimeFocused)
                ? timeOnFocusRef.current.available.at(0)
                : currentTimeFocused;

            if (isUndefined(timeOnFocus)) {
                return;
            }

            const buttonListOnFocus = Object.values(timeOnFocusRef.current.timesNodes[timeOnFocus]);

            const firstButton = buttonListOnFocus.at(0);
            const lastButton = buttonListOnFocus.at(-1);

            if (type === 'next') {
                if (lastButton === document.activeElement) {
                    firstButton?.focus();
                } else {
                    (document.activeElement?.nextElementSibling as HTMLButtonElement)?.focus();
                    (document.activeElement?.nextElementSibling as HTMLButtonElement)?.scrollIntoView({
                        block: 'nearest',
                    });
                }
            } else if (firstButton === document.activeElement) {
                lastButton?.focus();
            } else {
                (document.activeElement?.previousElementSibling as HTMLButtonElement)?.focus();
                (document.activeElement?.previousElementSibling as HTMLButtonElement)?.scrollIntoView({
                    block: 'nearest',
                });
            }
        };

        const handleUserInputDirection = (e: KeyboardEvent) => {
            if (e.key === 'ArrowUp') {
                handleTimeFocus('prev');
            }

            if (e.key === 'ArrowDown') {
                handleTimeFocus('next');
            }

            if (e.key === 'ArrowRight') {
                handleNextOnFocus();
            }

            if (e.key === 'ArrowLeft') {
                handlePrevOnFocus();
            }
        };

        contentElement?.addEventListener('keydown', handleUserInputDirection, {
            signal: controller.signal,
        });

        return () => {
            controller.abort();
        };
    }, [currentTimeFocused, handleNextOnFocus, handlePrevOnFocus]);

    useEffect(() => {
        if (value && isDate(value)) {
            const currentValue = valueRef.current;

            if (isDate(currentValue)) {
                const isSameHour = currentValue.getHours() === value.getHours();
                const isSameMinute = currentValue.getMinutes() === value.getMinutes();
                const isSameSecond = currentValue.getSeconds() === value.getSeconds();
                const isSameTime = isSameHour && isSameMinute && isSameSecond;

                if (isSameTime) {
                    valueRef.current = value;

                    return;
                }
            }

            const newTimeState = {
                hour: value.getHours(),
                minute: value.getMinutes(),
                second: value.getSeconds(),
            };

            setTimeState(newTimeState);
            valueRef.current = value;
        }
    }, [value]);

    useEffect(() => {
        if (isInitialRender.current) {
            isInitialRender.current = false;
        } else {
            const now = valueRef.current ?? new Date();
            const newDate = new Date(now);
            newDate.setHours(timeState?.hour ?? newDate.getHours());
            newDate.setMinutes(timeState?.minute ?? newDate.getMinutes());
            newDate.setSeconds(timeState?.second ?? newDate.getSeconds());

            onChangeTimeRef.current?.(timeState ? newDate : null, time);
        }
    }, [time, timeState]);

    return (
        <TimePickerContext.Provider
            // eslint-disable-next-line react/jsx-no-constructed-context-values
            value={{
                disabled,
                disabledHours,
                disabledMinutes,
                disabledSeconds,
                timeState,
                allowEmpty,
                styleGuide,
                currentTimeFocused,
                contentRef,
                closeButtonRef,
                timeOnFocusRef,
                setTimeState,
                registerTimes,
                handleTimeClick,
                handleClearTime,
                handleSelectCurrentTime,
                calculateDisabledTime,
            }}
        >
            {children}
            <input name="timePicker" type="time" value={time} readOnly hidden />
        </TimePickerContext.Provider>
    );
}

export function useTimePicker(componentName: string) {
    const timePickerContext = useContext(TimePickerContext);

    if (!timePickerContext) {
        throw new Error(`TimePicker.${componentName} has to be inside the TimePicker.Root component`);
    }

    return timePickerContext;
}
