import { ButtonHTMLAttributes, ElementRef, HTMLAttributes, forwardRef } from 'react';
import {
    DialogOverlay,
    Title,
    Description,
    Content,
    type DialogOverlayProps,
    type DialogTitleProps,
    type DialogDescriptionProps,
    type DialogContentProps,
} from '@radix-ui/react-dialog';
import { VariantProps, tv } from '@lib/tailwind-variants';
import { cn } from '@helpers/index';
import { useOverlay } from './context';

const customOverlayVariants = tv({
    base: 'animate-overlayShow z-modal/overlay absolute inset-0',

    variants: {
        styleGuide: {
            coremedia: 'bg-coremedia-grey-500/60',

            new: 'bg-gray-150/80',
        },
    },

    defaultVariants: {
        styleGuide: 'coremedia',
    },
});

type CustomOverlayProps = DialogOverlayProps & VariantProps<typeof customOverlayVariants>;

export const CustomOverlay = forwardRef<ElementRef<typeof DialogOverlay>, CustomOverlayProps>(
    ({ className, ...rest }, forwardedRef) => {
        const { styleGuide } = useOverlay('CustomOverlay');

        return (
            <DialogOverlay ref={forwardedRef} className={customOverlayVariants({ styleGuide, className })} {...rest} />
        );
    },
);

const contentCloseButtonVariants = tv({
    base: [
        'border-none bg-transparent',
        'absolute right-6',
        'flex cursor-pointer items-center justify-center',
        '[&_i]:text-xxs [&_i]:transition-all',
    ],

    variants: {
        styleGuide: {
            coremedia: ['top-3 size-5', '[&_i]:text-coremedia-black [&_i]:opacity-20 [&_i]:hover:opacity-50'],

            new: ['top-6 size-6', '[&_i]:hover:text-gray-250 [&_i]:text-gray-100'],
        },
    },

    defaultVariants: {
        styleGuide: 'coremedia',
    },
});

type ContentCloseButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
    VariantProps<typeof contentCloseButtonVariants>;

export const ContentCloseButton = forwardRef<HTMLButtonElement, ContentCloseButtonProps>(
    ({ className, ...rest }, forwardedRef) => {
        const { styleGuide } = useOverlay('ContentCloseButton');

        return (
            <button
                ref={forwardedRef}
                type="button"
                className={contentCloseButtonVariants({ styleGuide, className })}
                {...rest}
            />
        );
    },
);

const customHeaderVariants = tv({
    base: 'flex border-0 border-b border-solid pl-6 pr-12',

    variants: {
        styleGuide: {
            coremedia: 'border-coremedia-grey-200 h-[46px] py-3',

            new: 'border-coremedia-grey-200 min-h-[calc(1.5rem_*_3)] items-center py-6',
        },
    },

    defaultVariants: {
        styleGuide: 'coremedia',
    },
});

type CustomHeaderProps = ButtonHTMLAttributes<HTMLButtonElement> & VariantProps<typeof customHeaderVariants>;

export const CustomHeader = forwardRef<HTMLButtonElement, CustomHeaderProps>(({ className, ...rest }, forwardedRef) => {
    const { styleGuide } = useOverlay('CustomHeader');

    return <header ref={forwardedRef} className={customHeaderVariants({ styleGuide, className })} {...rest} />;
});

const customTitleVariants = tv({
    base: 'text-coremedia-black m-0 font-semibold',

    variants: {
        styleGuide: {
            coremedia: 'trailing-normal text-[13px] leading-5',

            new: 'flex items-center text-sm [&_i]:mr-4',
        },
    },

    defaultVariants: {
        styleGuide: 'coremedia',
    },
});

type CustomTitleProps = DialogTitleProps & VariantProps<typeof customTitleVariants>;

export const CustomTitle = forwardRef<ElementRef<typeof Title>, CustomTitleProps>(
    ({ className, ...rest }, forwardedRef) => {
        const { styleGuide } = useOverlay('CustomTitle');

        return <Title ref={forwardedRef} className={customTitleVariants({ styleGuide, className })} {...rest} />;
    },
);

export const CustomDescription = forwardRef<ElementRef<typeof Description>, DialogDescriptionProps>(
    ({ className, ...rest }, forwardedRef) => (
        <Description ref={forwardedRef} className={cn('m-0', className)} {...rest} />
    ),
);

CustomDescription.displayName = 'Overlay.Description';

const customFooterVariants = tv({
    base: 'item-center border-top mt-auto flex justify-end border-0 border-solid',

    variants: {
        styleGuide: {
            coremedia: 'border-coremedia-grey-200 bg-coremedia-grey-100 h-[46px] gap-10 px-6 py-2.5',

            new: 'border-gray-100 px-6 py-4',
        },
    },

    defaultVariants: {
        styleGuide: 'coremedia',
    },
});

type CustomFooterProps = HTMLAttributes<HTMLElement> & VariantProps<typeof customFooterVariants>;

export const CustomFooter = forwardRef<HTMLElement, CustomFooterProps>(({ className, ...rest }, forwardedRef) => {
    const { styleGuide } = useOverlay('Footer');

    return <footer ref={forwardedRef} className={customFooterVariants({ styleGuide, className })} {...rest} />;
});

CustomFooter.displayName = 'Overlay.Footer';

const customContentVariants = tv({
    base: ['z-modal/content absolute inset-0', 'mx-auto my-0 flex h-fit w-full flex-col bg-white'],

    variants: {
        styleGuide: {
            coremedia:
                'animate-overlayFadeIn max-h-[80vh] overflow-auto rounded-b-[6px] shadow-[0_0_4px_0_rgba(0,0,0,0.36)]',

            new: 'max-h-[calc(100vh_-_3rem)] max-w-[690px] rounded-b-md',
        },

        size: {
            xs: 'w-[300px]',

            sm: 'w-[400px]',

            md: 'w-[500px]',

            lg: 'w-[800px]',

            xl: 'w-[1024px]',
        },
    },

    compoundVariants: [
        {
            styleGuide: 'new',
            className: 'w-full',
        },
    ],

    defaultVariants: {
        styleGuide: 'coremedia',
        size: 'md',
    },
});

type CustomContentProps = DialogContentProps & VariantProps<typeof customContentVariants>;

export const CustomContent = forwardRef<ElementRef<typeof Content>, CustomContentProps>(
    ({ className, size, ...rest }, forwardedRef) => {
        const { styleGuide } = useOverlay('Content');

        return (
            <Content ref={forwardedRef} className={customContentVariants({ styleGuide, size, className })} {...rest} />
        );
    },
);
