import { useEffect } from 'react';
import { format } from 'date-fns';
import { DateFormat, DatePickerSingle, getYearsRangeForDatePicker } from '@components/DatePicker';
import { useFilter } from '@components/Filter/context';
import { FilterOptionEmptyOrDate, FilterPropOptions } from '@components/Filter/types';

export type DateOptionsProps = {
    filterOption: FilterOptionEmptyOrDate;
    onChange: (filterChanges: FilterOptionEmptyOrDate) => void;
    options: FilterPropOptions;
};

const { TO_YEAR, FROM_YEAR } = getYearsRangeForDatePicker();

export function DateOptions({ filterOption, onChange, options: { locale, resources } }: DateOptionsProps) {
    const { customDateFnsLocale, customPlaceholder, customPlaceholderTitle } = resources;

    const { containerRef } = useFilter('DateOptions');

    const handleSelectChange = (newText: Date | undefined) => {
        onChange({
            ...filterOption,
            value: format(newText!, DateFormat.longLocalizedDate),
        });
    };

    const date = filterOption.value ? new Date(filterOption.value) : new Date();

    useEffect(() => {
        if (!filterOption.value) {
            onChange({
                ...filterOption,
                value: format(new Date(), DateFormat.longLocalizedDate),
            });
        }
    }, [filterOption, onChange]);

    const datePickerCustomOptions = {
        customDateFnsLocale,
        customPlaceholder,
        customPlaceholderTitle,
    };

    return (
        <DatePickerSingle
            isPopover
            isPopoverWithInput
            closeWhenDateSelected
            selected={date}
            onSelect={handleSelectChange}
            disabled={!filterOption.editable}
            container={containerRef.current}
            options={datePickerCustomOptions}
            locale={locale}
            toYear={TO_YEAR}
            fromYear={FROM_YEAR}
            captionLayout="dropdown-buttons"
            styleGuide="new"
        />
    );
}
