import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState, type ReactNode } from 'react';
import {
    useSensors,
    useSensor,
    PointerSensor,
    KeyboardSensor,
    type DragStartEvent,
    type DragMoveEvent,
    type DragEndEvent,
    type UniqueIdentifier,
    type Announcements,
    type DragOverEvent,
    type SensorDescriptor,
    type SensorOptions,
    type DragCancelEvent,
} from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { deepClone } from '@sidetalk/helpers';
import {
    buildTree,
    flattenTree,
    getProjection,
    MAX_DEFAULT_DEPTH,
    removeChildrenOf,
    setProperty,
    type Projection,
} from '../helpers';
import { sortableTreeKeyboardCoordinates } from '../keyboardCoordinates';
import type { DraggableListResources, FlattenedTreeItem, SensorContext, TreeItem } from '../types';

type DraggableListContextProps = {
    items: TreeItem[];
    flattenedItems: FlattenedTreeItem[];
    allowCollapse: boolean;
    disabled: boolean;
    hasIndicator: boolean;
    indentationWidth: number;
    orderedElementId?: UniqueIdentifier;
    orderedElementOrder?: 'desc' | 'asc';
    maxDepth: number;
    resources: DraggableListResources;
    activeId: UniqueIdentifier | null;
    projected: Projection | null;
    sensors: SensorDescriptor<SensorOptions>[];
    sortedIds: UniqueIdentifier[];
    activeItem?: FlattenedTreeItem | null;
    announcements: Announcements;
    handleDragStart: (event: DragStartEvent) => void;
    handleDragMove: (event: DragMoveEvent) => void;
    handleDragOver: (event: DragOverEvent) => void;
    handleDragEnd: (event: DragEndEvent) => void;
    handleDragCancel: (event: DragCancelEvent) => void;
    handleCollapse: (id: UniqueIdentifier) => void;
    onUpdateElement: (elementId: UniqueIdentifier, props: TreeItem) => void;
    onItemEvents?: (element: TreeItem, action: string) => void;
    onResetLabel?: (element: TreeItem) => void;
};

const DraggableListContext = createContext<DraggableListContextProps | null>(null);

const DefaultResources: DraggableListResources = {
    sortable: 'Sortable',
    emptyElements: 'There are no elements to display',
    pickUpItem: `Picked up {activeItem}.`,
    cancelDrag: `Moving was cancelled. {activeItem} was dropped in its original position.`,
    dropBefore: `{activeItem} was dropped before {nextItem}.`,
    dropAfter: `{activeItem} was dropped after {previousItem}.`,
    dropUnder: `{activeItem} was dropped under {previousItem}.`,
    movedBefore: `{activeItem} was moved before {nextItem}.`,
    movedAfter: `{activeItem} was moved after {previousItem}.`,
    movedUnder: `{activeItem} was moved under {previousItem}.`,
    instructions: [
        'To pick up a sortable item, press the space bar.',
        'While dragging, use the arrow keys to move the item.',
        'Press space again to drop the item in its new position, or press escape to cancel.',
    ].join(' '),
    editActionButton: 'Edit',
    orderDescActionButton: 'Order descending',
    orderAscActionButton: 'Order ascending',
    fontActionButton: 'Font',
    viewActionButton: 'View',
    deleteActionButton: 'Delete',
} as const;

export type DraggableListContextProviderProps = {
    children: ReactNode;
    elements: TreeItem[];
    allowCollapse?: boolean;
    disabled?: boolean;
    hasIndicator?: boolean;
    indentationWidth?: number;
    orderedElementId?: UniqueIdentifier;
    orderedElementOrder?: 'desc' | 'asc';
    maxDepth?: number;
    resources?: Partial<DraggableListResources>;
    onItemEvents?: (element: TreeItem, action: string) => void;
    onUpdateElements?: (elements: TreeItem[]) => void;
    onResetLabel?: (element: TreeItem) => void;
    onDragStart?: (event: DragStartEvent) => void;
    onDragMove?: (event: DragMoveEvent) => void;
    onDragOver?: (event: DragOverEvent) => void;
    onDragEnd?: (event: DragEndEvent) => void;
    onDragCancel?: (event: DragCancelEvent) => void;
};

export function DraggableListContextProvider({
    elements,
    allowCollapse = false,
    disabled = false,
    hasIndicator = true,
    indentationWidth = 36,
    orderedElementId,
    orderedElementOrder,
    maxDepth = MAX_DEFAULT_DEPTH,
    resources,
    onItemEvents,
    onUpdateElements,
    onResetLabel,
    onDragStart,
    onDragMove,
    onDragOver,
    onDragEnd,
    onDragCancel,
    children,
}: DraggableListContextProviderProps) {
    const [items, setItems] = useState(() => elements);
    const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
    const [overId, setOverId] = useState<UniqueIdentifier | null>(null);
    const [offsetLeft, setOffsetLeft] = useState(0);
    const [currentPosition, setCurrentPosition] = useState<{
        parentId: UniqueIdentifier | null;
        overId: UniqueIdentifier;
    } | null>(null);

    const onUpdateElementsRef = useRef(onUpdateElements);
    const onDragStartRef = useRef(onDragStart);
    const onDragMoveRef = useRef(onDragMove);
    const onDragOverRef = useRef(onDragOver);
    const onDragEndRef = useRef(onDragEnd);
    const onDragCancelRef = useRef(onDragCancel);

    const mergedResources = useMemo(
        () => ({
            sortable: resources?.sortable ?? DefaultResources.sortable,
            emptyElements: resources?.emptyElements ?? DefaultResources.emptyElements,
            pickUpItem: resources?.pickUpItem ?? DefaultResources.pickUpItem,
            cancelDrag: resources?.cancelDrag ?? DefaultResources.cancelDrag,
            dropBefore: resources?.dropBefore ?? DefaultResources.dropBefore,
            dropAfter: resources?.dropAfter ?? DefaultResources.dropAfter,
            dropUnder: resources?.dropUnder ?? DefaultResources.dropUnder,
            movedBefore: resources?.movedBefore ?? DefaultResources.movedBefore,
            movedAfter: resources?.movedAfter ?? DefaultResources.movedAfter,
            movedUnder: resources?.movedUnder ?? DefaultResources.movedUnder,
            instructions: resources?.instructions ?? DefaultResources.instructions,
            editActionButton: resources?.editActionButton ?? DefaultResources.editActionButton,
            orderDescActionButton: resources?.orderDescActionButton ?? DefaultResources.orderDescActionButton,
            orderAscActionButton: resources?.orderAscActionButton ?? DefaultResources.orderAscActionButton,
            fontActionButton: resources?.fontActionButton ?? DefaultResources.fontActionButton,
            viewActionButton: resources?.viewActionButton ?? DefaultResources.viewActionButton,
            deleteActionButton: resources?.deleteActionButton ?? DefaultResources.deleteActionButton,
        }),
        [
            resources?.sortable,
            resources?.cancelDrag,
            resources?.dropAfter,
            resources?.dropBefore,
            resources?.dropUnder,
            resources?.movedAfter,
            resources?.movedBefore,
            resources?.movedUnder,
            resources?.emptyElements,
            resources?.pickUpItem,
            resources?.instructions,
            resources?.editActionButton,
            resources?.orderDescActionButton,
            resources?.orderAscActionButton,
            resources?.fontActionButton,
            resources?.viewActionButton,
            resources?.deleteActionButton,
        ],
    );

    const flattenedItems = useMemo(() => {
        const flattenedTree = flattenTree(items, maxDepth);
        const activeItem = flattenedTree.find(({ id }) => id === activeId);
        const collapsedItems = flattenedTree.reduce<UniqueIdentifier[]>((acc, { children, isExpanded = true, id }) => {
            if ((!isExpanded || (activeItem?.hasChildren && activeItem?.depth === 0)) && children?.length) {
                return [...acc, id];
            }

            return acc;
        }, []);

        return removeChildrenOf(flattenedTree, activeId ? [activeId, ...collapsedItems] : collapsedItems);
    }, [activeId, items, maxDepth]);

    const sensorContext: SensorContext = useRef({
        items: flattenedItems,
        offset: offsetLeft,
    });
    const [coordinateGetter] = useState(() =>
        sortableTreeKeyboardCoordinates(sensorContext, hasIndicator, indentationWidth),
    );

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter,
        }),
    );

    const projected =
        activeId && overId
            ? getProjection(flattenedItems, activeId, overId, offsetLeft, indentationWidth, maxDepth)
            : null;

    const handleDragStart = useCallback(
        (event: DragStartEvent) => {
            const {
                active: { id: activeId },
            } = event;
            setActiveId(activeId);
            setOverId(activeId);

            const activeItem = flattenedItems.find(({ id }) => id === activeId);

            if (activeItem) {
                setCurrentPosition({
                    parentId: activeItem.parentId,
                    overId: activeId,
                });
            }

            document.body.style.setProperty('cursor', 'grabbing');
            onDragStartRef.current?.(event);
        },
        [flattenedItems],
    );

    const handleDragMove = useCallback((event: DragMoveEvent) => {
        const { delta } = event;

        setOffsetLeft(delta.x);

        sensorContext.current = {
            ...sensorContext.current,
            offset: delta.x,
        };

        onDragMoveRef.current?.(event);
    }, []);

    const handleDragOver = useCallback((event: DragOverEvent) => {
        const { over } = event;

        setOverId(over?.id ?? null);

        onDragOverRef.current?.(event);
    }, []);

    const resetState = useCallback(() => {
        setOverId(null);
        setActiveId(null);
        setOffsetLeft(0);
        setCurrentPosition(null);

        document.body.style.setProperty('cursor', '');
    }, []);

    const handleDragEnd = useCallback(
        (event: DragEndEvent) => {
            const { active, over } = event;

            resetState();

            if (projected && over) {
                const clonedItems = deepClone(flattenTree(items, maxDepth));

                const currentProjection = getProjection(
                    clonedItems,
                    active.id,
                    over.id,
                    sensorContext.current.offset,
                    indentationWidth,
                    maxDepth,
                );
                const { depth, parentId } = currentProjection;

                const activeIndex = clonedItems.findIndex(({ id }) => id === active.id);
                const activeTreeItem = clonedItems[activeIndex];

                clonedItems[activeIndex] = { ...activeTreeItem, depth, parentId };

                const overIndex = clonedItems.findIndex(({ id }) => id === over.id);
                const sortedItems = arrayMove(clonedItems, activeIndex, overIndex);
                const newItems = buildTree(sortedItems);

                setItems(newItems);
                onUpdateElementsRef.current?.(newItems);
            }

            onDragEndRef.current?.(event);
        },
        [resetState, projected, items, maxDepth, indentationWidth],
    );

    const handleDragCancel = useCallback(
        (event: DragCancelEvent) => {
            resetState();

            onDragCancelRef.current?.(event);
        },
        [resetState],
    );

    const handleCollapse = useCallback(
        (id: UniqueIdentifier) => {
            if (!allowCollapse) {
                return;
            }

            const newItems = setProperty(items, id, 'isExpanded', (value) => {
                return !value;
            });

            setItems(newItems);
            onUpdateElementsRef.current?.(newItems);
        },
        [allowCollapse, items],
    );

    const getMovementAnnouncement = useCallback(
        (eventName: string, activeId: UniqueIdentifier, overId?: UniqueIdentifier) => {
            if (overId && projected) {
                const clonedItems = deepClone(flattenTree(items, maxDepth));

                const overIndex = clonedItems.findIndex(({ id }) => id === overId);
                const activeIndex = clonedItems.findIndex(({ id }) => id === activeId);

                const sortedItems = arrayMove(clonedItems, activeIndex, overIndex);

                const currentProjection = getProjection(
                    sortedItems,
                    activeId,
                    overId,
                    sensorContext.current.offset,
                    indentationWidth,
                    maxDepth,
                );

                if (eventName !== 'onDragEnd') {
                    if (
                        currentPosition &&
                        currentProjection.parentId === currentPosition.parentId &&
                        overId === currentPosition.overId
                    ) {
                        return;
                    } else {
                        setCurrentPosition({
                            parentId: currentProjection.parentId,
                            overId,
                        });
                    }
                }

                const activeTreeItem = clonedItems[activeIndex];
                const previousItem = sortedItems[overIndex - 1];

                const activeItemTitle = activeTreeItem?.title ?? '';
                const previousItemTitle = previousItem?.title ?? '';

                let announcement;
                const isDragEnd = eventName === 'onDragEnd';

                if (!previousItem) {
                    const nextItem = sortedItems[overIndex + 1];
                    const nextItemTitle = nextItem?.title ?? '';

                    if (isDragEnd) {
                        announcement = mergedResources.dropBefore
                            .replace('{activeItem}', activeItemTitle)
                            .replace('{nextItem}', nextItemTitle);
                    } else {
                        announcement = mergedResources.movedBefore
                            .replace('{activeItem}', activeItemTitle)
                            .replace('{nextItem}', nextItemTitle);
                    }
                } else {
                    const isDroppedUnder = isDragEnd && projected.depth > previousItem.depth;
                    const isMovedUnder = !isDragEnd && currentProjection.depth > previousItem.depth;

                    if (isDroppedUnder || isMovedUnder) {
                        if (isDragEnd) {
                            announcement = mergedResources.dropUnder
                                .replace('{activeItem}', activeItemTitle)
                                .replace('{previousItem}', previousItemTitle);
                        } else {
                            announcement = mergedResources.movedUnder
                                .replace('{activeItem}', activeItemTitle)
                                .replace('{previousItem}', previousItemTitle);
                        }
                    } else {
                        let previousSibling: FlattenedTreeItem | undefined = previousItem;
                        while (previousSibling && currentProjection.depth < previousSibling.depth) {
                            const parentId: UniqueIdentifier | null = previousSibling.parentId;
                            previousSibling = sortedItems.find(({ id }) => id === parentId);
                        }

                        if (previousSibling) {
                            if (isDragEnd) {
                                announcement = mergedResources.dropAfter
                                    .replace('{activeItem}', activeItemTitle)
                                    .replace('{previousItem}', previousItemTitle);
                            } else {
                                announcement = mergedResources.movedAfter
                                    .replace('{activeItem}', activeItemTitle)
                                    .replace('{previousItem}', previousItemTitle);
                            }
                        }
                    }
                }

                return announcement;
            }

            return;
        },
        [
            projected,
            items,
            maxDepth,
            indentationWidth,
            currentPosition,
            mergedResources.dropBefore,
            mergedResources.movedBefore,
            mergedResources.dropUnder,
            mergedResources.movedUnder,
            mergedResources.dropAfter,
            mergedResources.movedAfter,
        ],
    );

    const onUpdateElement = useCallback((elementId: UniqueIdentifier, newProps: TreeItem) => {
        setItems((oldState) => {
            // Helper function to update a single element
            const updateElement = (element: TreeItem): TreeItem => {
                if (element.id === elementId) {
                    return { ...element, ...newProps };
                }
                return element;
            };

            // Helper function to recursively process elements and their children
            const processElement = (element: TreeItem): TreeItem => {
                const updatedElement = updateElement(element);

                if (element.children?.length) {
                    return {
                        ...updatedElement,
                        children: element.children.map(processElement),
                    };
                }

                return updatedElement;
            };

            // Process all elements
            const newElements = oldState.map(processElement);

            onUpdateElementsRef.current?.(newElements);

            return newElements;
        });
    }, []);

    const sortedIds = useMemo(() => flattenedItems.map(({ id }) => id), [flattenedItems]);
    const activeItem = activeId ? flattenedItems.find(({ id }) => id === activeId) : null;

    const announcements: Announcements = useMemo(
        () => ({
            onDragStart({ active }) {
                const item = flattenedItems.find(({ id }) => id === active.id);

                return mergedResources.pickUpItem.replace('{activeItem}', item?.title ?? '');
            },
            onDragMove({ active, over }) {
                return getMovementAnnouncement('onDragMove', active.id, over?.id);
            },
            onDragOver({ active, over }) {
                return getMovementAnnouncement('onDragOver', active.id, over?.id);
            },
            onDragEnd({ active, over }) {
                return getMovementAnnouncement('onDragEnd', active.id, over?.id);
            },
            onDragCancel() {
                return mergedResources.cancelDrag.replace('{activeItem}', activeItem?.title ?? '');
            },
        }),
        [activeItem, flattenedItems, getMovementAnnouncement, mergedResources.pickUpItem, mergedResources.cancelDrag],
    );

    useEffect(() => {
        sensorContext.current = {
            ...sensorContext.current,
            items: flattenedItems,
        };
    }, [flattenedItems]);

    useEffect(() => {
        setItems(elements);
    }, [elements]);

    useEffect(() => {
        onUpdateElementsRef.current = onUpdateElements;
    }, [onUpdateElements]);

    useEffect(() => {
        onDragStartRef.current = onDragStart;
    }, [onDragStart]);

    useEffect(() => {
        onDragMoveRef.current = onDragMove;
    }, [onDragMove]);

    useEffect(() => {
        onDragOverRef.current = onDragOver;
    }, [onDragOver]);

    useEffect(() => {
        onDragEndRef.current = onDragEnd;
    }, [onDragEnd]);

    useEffect(() => {
        onDragCancelRef.current = onDragCancel;
    }, [onDragCancel]);

    const draggableListContextValue = useMemo(
        () => ({
            items,
            flattenedItems,
            allowCollapse,
            disabled,
            hasIndicator,
            indentationWidth,
            orderedElementId,
            orderedElementOrder,
            maxDepth,
            resources: mergedResources,
            activeId,
            projected,
            sensors,
            sortedIds,
            activeItem,
            announcements,
            handleDragStart,
            handleDragMove,
            handleDragOver,
            handleDragEnd,
            handleDragCancel,
            handleCollapse,
            onUpdateElement,
            onItemEvents,
            onResetLabel,
        }),
        [
            items,
            flattenedItems,
            allowCollapse,
            disabled,
            hasIndicator,
            indentationWidth,
            orderedElementId,
            orderedElementOrder,
            maxDepth,
            mergedResources,
            activeId,
            projected,
            sensors,
            sortedIds,
            activeItem,
            announcements,
            handleDragStart,
            handleDragMove,
            handleDragOver,
            handleDragEnd,
            handleDragCancel,
            handleCollapse,
            onUpdateElement,
            onItemEvents,
            onResetLabel,
        ],
    );

    return <DraggableListContext.Provider value={draggableListContextValue}>{children}</DraggableListContext.Provider>;
}

export function useDraggableList(componentName: string) {
    const draggableListContext = useContext(DraggableListContext);

    if (!draggableListContext) {
        throw new Error(`DraggableList.${componentName} has to be inside the DraggableList.Root component`);
    }

    return draggableListContext;
}
