import { ElementRef, forwardRef } from 'react';
import {
    Root,
    Image,
    Fallback,
    type AvatarProps,
    type AvatarImageProps,
    type AvatarFallbackProps,
} from '@radix-ui/react-avatar';
import { tv, VariantProps } from '@lib/tailwind-variants';
import { cn } from '@helpers/index';

const avatarRootVariant = tv({
    base: [
        'aspect-square w-10 select-none overflow-hidden',
        'inline-flex items-center justify-center',
        'rounded-full border-solid',
    ],

    variants: {
        border: {
            none: 'border-0',
            thin: 'border',
            medium: 'border-[3px]',
            bold: 'border-[5px]',
        },

        borderColor: {
            red: 'border-red-500',
            yellow: 'border-yellow-500',
            green: 'border-green-500',
            turquoise: 'border-turquoise-500',
            purple: 'border-purple-500',
            blue: 'border-blue-500',
        },
    },

    defaultVariants: {
        border: 'none',
        borderColor: 'red',
    },
});

type CustomRootProps = AvatarProps & VariantProps<typeof avatarRootVariant>;

export const CustomRoot = forwardRef<ElementRef<typeof Root>, CustomRootProps>(
    ({ border, borderColor, className, ...rest }, forwardedRef) => (
        <Root
            ref={forwardedRef}
            className={avatarRootVariant({
                border,
                borderColor,
                className,
            })}
            {...rest}
        />
    ),
);

CustomRoot.displayName = 'Avatar.Root';

export const CustomImage = forwardRef<ElementRef<typeof Image>, AvatarImageProps>(
    ({ className, ...rest }, forwardedRef) => (
        <Image ref={forwardedRef} className={cn('size-full object-cover', className)} {...rest} />
    ),
);

CustomImage.displayName = 'Avatar.Image';

export const CustomFallback = forwardRef<ElementRef<typeof Fallback>, AvatarFallbackProps>(
    ({ className, ...rest }, forwardedRef) => (
        <Fallback
            ref={forwardedRef}
            className={cn('text-md font-regular leading-base flex size-full items-center justify-center', className)}
            {...rest}
        />
    ),
);

CustomFallback.displayName = 'Avatar.Fallback';
