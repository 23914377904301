import { ChangeEvent, MouseEvent, useCallback, useMemo, useState } from 'react';
import { ActiveModifiers, SelectSingleEventHandler } from 'react-day-picker';
import { format, isValid, Locale, parse } from 'date-fns';
import { customMask, deepClone } from '@sidetalk/helpers';
import { Popover } from '@components/Popover';
import { Icon } from '@components/Icon';
import { DateFormat } from '@components/DatePicker';
import { CustomTriggerButton } from '@components/DatePicker/styles';
import { DatePickerSingleProps } from '@components/DatePicker/types';
import {
    buildIsDayDisabled,
    getDateFormatsOnOldStyle,
    getDateLocale,
    getDatePlaceholder,
    getPlaceholderTitle,
    preserveDayTime,
} from '@components/DatePicker/helpers';
import { DatePickerRoot } from '../Root';
import { DatePickerFooter } from '../Footer';
import { DateTimePicker } from '../DateTimePicker';

type OnChangeSinglePropsType = [
    day: Date | undefined,
    selectedDay: Date,
    activeModifiers: ActiveModifiers,
    e: MouseEvent<Element, globalThis.MouseEvent>,
];

const formatDateToInput = (date: Date, locale: Locale) =>
    format(date, DateFormat.longLocalizedDate, { locale }).replace(/\W/g, '');

const COMPLETE_DATE_LENGTH = 10;

export function DatePickerSingle({
    isPopover = false,
    isPopoverWithInput = false,
    closeWhenDateSelected = false,
    selected,
    locale,
    container,
    options,
    onSelect,
    styleGuide = 'coremedia',
    variant = 'secondary',
    hasButtonFooter = false,
    timePicker = false,
    timePickerSeconds = false,
    maxDate,
    minDate,
    cancelLabel,
    acceptLabel,
    disabledPopover = false,
    isInvalidDate,
    showLocalizedDate,
    ...rest
}: DatePickerSingleProps) {
    const dateLocale = options?.customDateFnsLocale ?? getDateLocale(locale);

    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const [previousSelectedDay, setPreviousSelectedDay] = useState(deepClone(selected) ?? new Date());
    const [selectedDay, setSelectedDay] = useState(selected);
    const [inputDate, setInputDate] = useState(selected ? formatDateToInput(selected, dateLocale) : '');
    const [hasInputError, setHasInputError] = useState(false);
    const [persistedValue, setPersistedValue] = useState<OnChangeSinglePropsType | null>([
        selected!,
        selected!,
        {} as ActiveModifiers,
        {} as MouseEvent<Element, globalThis.MouseEvent>,
    ]);

    const isDayDisabled = useMemo(() => buildIsDayDisabled(minDate, maxDate), [minDate, maxDate]);

    const hasTimePicker = timePicker;
    const shouldShowFooter = hasButtonFooter || hasTimePicker;

    const onChangeTime = useCallback((time: Date) => {
        setSelectedDay(time);

        setPersistedValue((oldState) => {
            const newPersistedValue = deepClone(oldState);

            if (newPersistedValue !== null) {
                newPersistedValue[0] = time;
                newPersistedValue[1] = time;
            }

            return newPersistedValue;
        });
    }, []);

    if (isPopover) {
        const popoverTitle = selected ? getPlaceholderTitle(options?.customPlaceholderTitle, locale) : undefined;

        const handleOnSelectOld: SelectSingleEventHandler = (...props) => {
            const newDate = preserveDayTime(props[0], selectedDay);
            const newProps = deepClone(props);
            newProps[0] = newDate;

            if (newProps[0]) {
                setSelectedDay(newProps[0]);
            }

            if (closeWhenDateSelected && !hasButtonFooter && !hasTimePicker) {
                setIsPopoverOpen(false);
            }

            if (onSelect && !hasButtonFooter && !hasTimePicker && newProps[0]) {
                setPreviousSelectedDay(newProps[0]);
                onSelect(...newProps);

                if (closeWhenDateSelected) {
                    setIsPopoverOpen(false);
                }
            }

            if (hasButtonFooter || hasTimePicker) {
                setPersistedValue(newProps);
            }
        };

        const handleOnSelect: SelectSingleEventHandler = (...props) => {
            const newDate = preserveDayTime(props[0], selectedDay);
            const newProps = deepClone(props);
            newProps[0] = newDate;

            if (closeWhenDateSelected && !hasButtonFooter && !hasTimePicker) {
                setIsPopoverOpen(false);
            }

            if (newProps[0]) {
                setInputDate(format(newProps[0], DateFormat.longLocalizedDate).replace(/\W/g, ''));
                setSelectedDay(newProps[0]);
            }

            if (onSelect && !hasButtonFooter && !hasTimePicker && newProps[0]) {
                setPreviousSelectedDay(newProps[0]);
                onSelect(...newProps);

                if (closeWhenDateSelected) {
                    setIsPopoverOpen(false);
                }
            }

            if (hasButtonFooter || hasTimePicker) {
                setPersistedValue(newProps);
            }
        };

        const formatSingleDate = (dayToFormat: Date) => {
            if (!dayToFormat) {
                let formatted = getDatePlaceholder(options?.customPlaceholder, locale);

                if (timePickerSeconds) {
                    formatted += ` ${DateFormat.timeWithSeconds}`;

                    return formatted;
                }

                if (timePicker) {
                    formatted += ` ${DateFormat.time}`;
                }

                return formatted;
            }

            const { formatDate, formatDateAndTime, formatDateAndTimeWithSeconds } =
                getDateFormatsOnOldStyle(showLocalizedDate);

            if (dayToFormat?.getSeconds() === 0 && dayToFormat?.getMinutes() === 0 && dayToFormat?.getHours() === 0) {
                return format(dayToFormat, formatDate, { locale: dateLocale });
            }

            if (timePickerSeconds) {
                return format(dayToFormat, formatDateAndTimeWithSeconds, { locale: dateLocale });
            }

            if (timePicker) {
                return format(dayToFormat, formatDateAndTime, { locale: dateLocale });
            }

            return format(dayToFormat, formatDate, { locale: dateLocale });
        };

        const shouldShowIcon = styleGuide === 'new' || (styleGuide === 'coremedia' && variant === 'boxed');
        const iconType = 'byside';
        const iconName = styleGuide === 'new' ? 'schedule-calls' : 'calendar';

        if (styleGuide === 'coremedia') {
            return (
                <Popover.Root open={isPopoverOpen} onOpenChange={disabledPopover ? undefined : setIsPopoverOpen}>
                    <Popover.Trigger asChild>
                        <CustomTriggerButton
                            disabled={disabledPopover}
                            variant={variant}
                            styleGuide={styleGuide}
                            title={popoverTitle}
                        >
                            {options?.customDateFormatter?.(previousSelectedDay) ??
                                formatSingleDate(previousSelectedDay)}
                            {shouldShowIcon && <Icon type={iconType} iconName={iconName} />}
                            {!shouldShowIcon && <Icon type="byside" iconName="arrow-small" />}
                        </CustomTriggerButton>
                    </Popover.Trigger>
                    <Popover.Content container={container} styleGuide={styleGuide}>
                        <DatePickerRoot
                            styleGuide={styleGuide}
                            mode="single"
                            selected={selectedDay}
                            locale={dateLocale}
                            onSelect={handleOnSelectOld}
                            disabled={isInvalidDate ? (day) => isInvalidDate(day) || isDayDisabled(day) : isDayDisabled}
                            defaultMonth={selectedDay}
                            footer={
                                <>
                                    <DateTimePicker
                                        timeStart={selectedDay ?? null}
                                        showTimePicker={hasTimePicker}
                                        onChangeTime={onChangeTime}
                                        hasSeconds={timePickerSeconds}
                                        styleGuide={styleGuide}
                                    />
                                    <DatePickerFooter
                                        showFooter={shouldShowFooter}
                                        canSave={!!persistedValue}
                                        cancelLabel={cancelLabel}
                                        acceptLabel={acceptLabel}
                                        cancelFunction={() => {
                                            setSelectedDay(deepClone(previousSelectedDay));
                                            setIsPopoverOpen(false);
                                        }}
                                        acceptFunction={() => {
                                            if (persistedValue !== null) {
                                                onSelect?.(...persistedValue);
                                                setPreviousSelectedDay(deepClone(selectedDay!));
                                                setIsPopoverOpen(false);
                                            }
                                        }}
                                    />
                                </>
                            }
                            {...rest}
                        />
                        <Popover.Arrow />
                    </Popover.Content>
                </Popover.Root>
            );
        }

        const placeholder = getDatePlaceholder(options?.customPlaceholder, locale);

        const handleInputChangeDate = (e: ChangeEvent<HTMLInputElement>) => {
            const unformattedValue = e.target.value;
            const value = unformattedValue.replace(/\W/g, '');

            setInputDate(value);

            if (unformattedValue.length === COMPLETE_DATE_LENGTH) {
                const newDate = parse(unformattedValue, DateFormat.longLocalizedDate, new Date(), {
                    locale: dateLocale,
                });

                if (isValid(newDate)) {
                    setSelectedDay(newDate);
                    setHasInputError(false);
                    onSelect?.(newDate, newDate, {}, e as unknown as MouseEvent<HTMLInputElement>);
                } else {
                    setHasInputError(true);
                }
            }
        };

        return (
            <Popover.Root open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
                <Popover.Trigger asChild>
                    <CustomTriggerButton
                        disabled={disabledPopover}
                        variant={variant}
                        styleGuide={styleGuide}
                        title={popoverTitle}
                        isPopoverWithInput={isPopoverWithInput}
                        hasError={hasInputError}
                    >
                        {isPopoverWithInput && (
                            <input
                                placeholder={placeholder}
                                value={customMask(inputDate, placeholder)}
                                onChange={handleInputChangeDate}
                                onClick={(e) => e.stopPropagation()}
                            />
                        )}
                        {!isPopoverWithInput &&
                            (selectedDay
                                ? format(selectedDay, DateFormat.longLocalizedDate, { locale: dateLocale })
                                : placeholder)}
                        <div>
                            {hasInputError && <Icon type={iconType} iconName="alerts" />}
                            {shouldShowIcon && <Icon type={iconType} iconName={iconName} />}
                            {!shouldShowIcon && <Icon type="byside" iconName="arrow-small" />}
                        </div>
                    </CustomTriggerButton>
                </Popover.Trigger>
                <Popover.Content container={container} styleGuide={styleGuide}>
                    <DatePickerRoot
                        styleGuide={styleGuide}
                        mode="single"
                        selected={selectedDay}
                        locale={dateLocale}
                        onSelect={handleOnSelect}
                        disabled={isInvalidDate ? (day) => isInvalidDate(day) || isDayDisabled(day) : isDayDisabled}
                        footer={
                            <>
                                <DateTimePicker
                                    timeStart={selectedDay ?? null}
                                    showTimePicker={hasTimePicker}
                                    onChangeTime={onChangeTime}
                                    hasSeconds={timePickerSeconds}
                                    styleGuide={styleGuide}
                                />
                                <DatePickerFooter
                                    showFooter={shouldShowFooter}
                                    cancelFunction={() => {
                                        setSelectedDay(deepClone(previousSelectedDay));
                                        setIsPopoverOpen(false);
                                    }}
                                    acceptFunction={() => {
                                        if (persistedValue !== null) {
                                            onSelect?.(...persistedValue);

                                            if (closeWhenDateSelected) {
                                                setIsPopoverOpen(false);
                                            }
                                        }
                                    }}
                                    canSave={!!persistedValue}
                                    cancelLabel={cancelLabel}
                                    acceptLabel={acceptLabel}
                                />
                            </>
                        }
                        {...rest}
                    />
                </Popover.Content>
            </Popover.Root>
        );
    }

    return (
        <DatePickerRoot
            styleGuide={styleGuide}
            mode="single"
            selected={selected}
            locale={dateLocale}
            disabled={isInvalidDate ? (day) => isInvalidDate(day) || isDayDisabled(day) : isDayDisabled}
            {...rest}
        />
    );
}
