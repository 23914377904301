import {
    ButtonHTMLAttributes,
    ChangeEvent,
    ComponentPropsWithoutRef,
    ElementRef,
    ElementType,
    InputHTMLAttributes,
    forwardRef,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { customMask, isFunction } from '@sidetalk/helpers';
import { CustomScrollArea } from '@components/CustomScrollArea';
import { Popover } from '@components/Popover';
import { Button } from '@components/Button';
import { Icon } from '@components/Icon';
import { cn } from '@helpers/index';
import { TimePickerContextProvider, TimePickerContextProviderProps, useTimePicker } from './context';
import { HOURS_IN_A_DAY, MINUTES_IN_A_HOUR, SECONDS_IN_A_MINUTE } from './constants';
import { generateTime } from './helpers';
import {
    Body,
    ClearButton,
    CloseButton,
    Content,
    CustomTriggerButton,
    Footer,
    NowButton,
    TimeButton,
    Trigger,
} from './styles';
import { UnitOfTime } from './types';

type RootProps = TimePickerContextProviderProps & ComponentPropsWithoutRef<typeof Popover.Root>;

function Root({
    disabledHours,
    disabledMinutes,
    disabledSeconds,
    disabled,
    calculateDisabledTime,
    defaultValue,
    value,
    styleGuide,
    allowEmpty,
    onChangeTime,
    ...rest
}: RootProps) {
    return (
        <TimePickerContextProvider
            disabledHours={disabledHours}
            disabledMinutes={disabledMinutes}
            disabledSeconds={disabledSeconds}
            disabled={disabled}
            calculateDisabledTime={calculateDisabledTime}
            defaultValue={defaultValue}
            allowEmpty={allowEmpty}
            value={value}
            styleGuide={styleGuide}
            onChangeTime={onChangeTime}
        >
            <Popover.Root {...rest} />
        </TimePickerContextProvider>
    );
}

Root.displayName = 'TimePicker.Root';

export type TimePickerRootProps = typeof Root;

type InputTimeProps = InputHTMLAttributes<HTMLInputElement> & {
    hasHours?: boolean;
    hasMinutes?: boolean;
    hasSeconds?: boolean;
};

const InputTime = forwardRef<HTMLInputElement, InputTimeProps>(
    ({ hasHours = true, hasMinutes = true, hasSeconds = false, ...rest }, forwardedRef) => {
        const [inputTime, setInputTime] = useState('');

        const { disabled, disabledHours, disabledMinutes, disabledSeconds, timeState, setTimeState } =
            useTimePicker('InputTime');

        const placeholder = useMemo(
            () => [hasHours && 'hh', hasMinutes && 'mm', hasSeconds && 'ss'].filter(Boolean).join(':'),
            [hasHours, hasMinutes, hasSeconds],
        );

        const disabledArrays = useMemo(
            () =>
                [hasHours && disabledHours, hasMinutes && disabledMinutes, hasSeconds && disabledSeconds].filter(
                    Boolean,
                ) as unknown as number[][],
            [hasHours, disabledHours, hasMinutes, disabledMinutes, hasSeconds, disabledSeconds],
        );

        const handleChangeTime = useCallback(
            (e: ChangeEvent<HTMLInputElement>) => {
                const unformattedValue = e.target.value;
                const value = unformattedValue.replace(/\W/g, '');
                setInputTime(value);

                if (placeholder.length === unformattedValue.length) {
                    const timeArray = unformattedValue.split(':').map(Number);

                    setTimeState((oldState) => {
                        if (oldState) {
                            return Object.keys(oldState).reduce((newTimeState, timeKey, index) => {
                                const isUserInputtingDisabledTime =
                                    disabledArrays[index]?.includes(timeArray[index]) ?? false;
                                const oldTime = oldState[timeKey as keyof typeof oldState];
                                const newTime = timeArray[index];

                                return {
                                    ...newTimeState,
                                    [timeKey]: isUserInputtingDisabledTime ? oldTime : newTime,
                                };
                            }, {});
                        }

                        return null;
                    });
                }
            },
            [placeholder.length, setTimeState, disabledArrays],
        );

        useEffect(() => {
            if (timeState) {
                const newTime = Object.values(timeState)
                    .map((time) => String(time).padStart(2, '0'))
                    .join('');

                setInputTime(newTime);
            }
        }, [timeState]);

        return (
            <input
                ref={forwardedRef}
                placeholder={placeholder}
                value={customMask(inputTime, placeholder)}
                onChange={handleChangeTime}
                onClick={(e) => e.stopPropagation()}
                disabled={disabled}
                {...rest}
            />
        );
    },
);

InputTime.displayName = 'TimePicker.InputTime';

type TimeListProps = ComponentPropsWithoutRef<typeof CustomScrollArea.Basic> & {
    step?: number;
    time: UnitOfTime;
    customButton?: ElementType<ButtonHTMLAttributes<HTMLButtonElement>>;
};

const TimeList = forwardRef<ElementRef<typeof CustomScrollArea.Basic>, Omit<TimeListProps, 'children'>>(
    ({ step = 1, time, customButton: CustomButton = TimeButton, className, ...rest }, forwardedRef) => {
        const {
            disabledHours,
            disabledMinutes,
            disabledSeconds,
            timeState,
            currentTimeFocused,
            timeOnFocusRef,
            registerTimes,
            handleTimeClick,
            calculateDisabledTime,
        } = useTimePicker('TimeList');

        const times = useMemo(() => {
            const timeValue = {
                hour: HOURS_IN_A_DAY,
                minute: MINUTES_IN_A_HOUR,
                second: SECONDS_IN_A_MINUTE,
            }[time];

            return generateTime(step, timeValue);
        }, [step, time]);

        const disabledTimes = useMemo(() => {
            if (isFunction(calculateDisabledTime) && timeState) {
                return calculateDisabledTime(timeState)?.[time] ?? [];
            }

            const timeValue = {
                hour: disabledHours,
                minute: disabledMinutes,
                second: disabledSeconds,
            }[time];

            return timeValue;
        }, [disabledHours, disabledMinutes, disabledSeconds, time, timeState, calculateDisabledTime]);

        useEffect(() => {
            const unRegisterTime = registerTimes(time);

            return () => {
                unRegisterTime();
            };
        }, [registerTimes, time]);

        useEffect(() => {
            if (timeState) {
                timeOnFocusRef.current.timesNodes[time][timeState?.[time] ?? 0]?.scrollIntoView({
                    block: 'center',
                });
            }
        }, [time, timeOnFocusRef, timeState]);

        return (
            <CustomScrollArea.Basic
                ref={forwardedRef}
                className={cn('max-h-[200px] pr-2', className)}
                {...rest}
                onKeyDown={(e) => {
                    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                        e.preventDefault();
                    }
                }}
            >
                {times.map((timeButton) => (
                    <CustomButton
                        key={timeButton}
                        ref={(node: HTMLButtonElement) => {
                            timeOnFocusRef.current.timesNodes[time] = {
                                ...timeOnFocusRef.current.timesNodes[time],
                                [timeButton]: node,
                            };
                        }}
                        aria-hidden={currentTimeFocused !== time}
                        tabIndex={currentTimeFocused !== time ? -1 : 0}
                        data-checked={timeState?.[time] === timeButton}
                        onClick={() => handleTimeClick(timeButton, time)}
                        disabled={disabledTimes.includes(timeButton)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' || e.key === ' ') {
                                handleTimeClick(timeButton, time);
                            }
                        }}
                    >
                        {String(timeButton).padStart(2, '0')}
                    </CustomButton>
                ))}
            </CustomScrollArea.Basic>
        );
    },
);

TimeList.displayName = 'TimePicker.TimeList';

type BasicProps = Omit<
    ComponentPropsWithoutRef<typeof Root> & ComponentPropsWithoutRef<typeof Content>,
    'children' | 'defaultValue'
> &
    (
        | {
              hasNowButton: true;
              nowButtonText: string;
          }
        | {
              hasNowButton?: false;
              nowButtonText?: string;
          }
    ) &
    (
        | {
              hasCloseButton: true;
              closeButtonText: string;
          }
        | {
              hasCloseButton?: false;
              closeButtonText?: string;
          }
    ) &
    (
        | {
              hasClearButton: true;
              clearButtonText: string;
          }
        | {
              hasClearButton?: false;
              clearButtonText?: string;
          }
    ) & {
        defaultValue?: Date;
        isPopoverWithInput?: boolean;
        hasFooter?: boolean;
        hasHours?: boolean;
        hasMinutes?: boolean;
        hasSeconds?: boolean;
        children?: string;
    };

const Basic = forwardRef<ElementRef<typeof Content>, BasicProps>(
    (
        {
            isPopoverWithInput = false,
            hasHours = true,
            hasMinutes = true,
            hasSeconds = false,
            hasFooter = false,
            hasNowButton = false,
            nowButtonText,
            hasCloseButton = false,
            closeButtonText,
            hasClearButton = false,
            clearButtonText,
            styleGuide,
            container,
            forceMount,
            children,
            ...rest
        },
        forwardedRef,
    ) => (
        <Root styleGuide={styleGuide} {...rest}>
            <Trigger asChild>
                <CustomTriggerButton
                    isPopoverWithInput={isPopoverWithInput}
                    aria-label={isPopoverWithInput ? children : ''}
                >
                    {!isPopoverWithInput && children}
                    {isPopoverWithInput && <InputTime hasSeconds={hasSeconds} />}
                    <Icon type="byside" iconName="time" />
                </CustomTriggerButton>
            </Trigger>
            <Content ref={forwardedRef} container={container} forceMount={forceMount}>
                <Body>
                    {hasHours && <TimeList time="hour" />}
                    {hasMinutes && <TimeList time="minute" />}
                    {hasSeconds && <TimeList time="second" />}
                </Body>
                {hasFooter && (hasNowButton || hasClearButton || hasCloseButton) && (
                    <Footer>
                        {hasNowButton && <NowButton>{nowButtonText}</NowButton>}
                        {hasClearButton && <ClearButton>{clearButtonText}</ClearButton>}
                        {hasCloseButton && (
                            <CloseButton asChild>
                                <Button styleGuide={styleGuide} size="sm">
                                    {closeButtonText}
                                </Button>
                            </CloseButton>
                        )}
                    </Footer>
                )}
            </Content>
        </Root>
    ),
);

Basic.displayName = 'TimePicker.Basic';

export const TimePicker = {
    Root,
    Trigger,
    CustomButton: CustomTriggerButton,
    InputTime,
    Content,
    Body,
    TimeList,
    TimeButton,
    Footer,
    NowButton,
    CloseButton,
    ClearButton,
    Basic,
};

export { useTimePicker };
