import { arrayMove } from '@dnd-kit/sortable';
import type { UniqueIdentifier } from '@dnd-kit/core';
import type { FlattenedTreeItem, TreeItem } from './types';

export const MAX_DEFAULT_DEPTH = 2;

const getDragDepth = (offset: number, indentationWidth: number) => {
    return Math.round(offset / indentationWidth);
};

type GetMaxDepthProps = {
    previousItem: FlattenedTreeItem;
    activeItem: FlattenedTreeItem;
    maxDepth: number;
};

const getMaxDepth = ({ previousItem, activeItem, maxDepth }: GetMaxDepthProps) => {
    if (previousItem) {
        if (previousItem.depth >= maxDepth) {
            return previousItem.depth;
        }

        if (activeItem.hasChildren && activeItem.depth === 0) {
            return activeItem.depth;
        }

        if (activeItem.hasChildren && (previousItem.hasChildren || !!previousItem.parentId)) {
            const isParent = activeItem.parentId === previousItem.id;

            if (isParent) {
                return activeItem.depth;
            }

            const isSameParent = activeItem.parentId === previousItem.parentId;

            return isSameParent ? previousItem.depth : previousItem.depth - 1;
        }

        return previousItem.hasChildren ? previousItem.depth + 1 : previousItem.depth;
    }

    return 0;
};

type GetMinDepthProps = {
    nextItem: FlattenedTreeItem;
};

const getMinDepth = ({ nextItem }: GetMinDepthProps) => {
    if (nextItem) {
        return nextItem.depth;
    }

    return 0;
};

export type Projection = {
    depth: number;
    maxDepth: number;
    minDepth: number;
    parentId: UniqueIdentifier | null;
};

export const getProjection = (
    items: FlattenedTreeItem[],
    activeId: UniqueIdentifier,
    overId: UniqueIdentifier,
    dragOffset: number,
    indentationWidth: number,
    maxDepth = MAX_DEFAULT_DEPTH,
): Projection => {
    const overItemIndex = items.findIndex(({ id }) => id === overId);
    const activeItemIndex = items.findIndex(({ id }) => id === activeId);

    const activeItem = items[activeItemIndex];
    const newItems = arrayMove(items, activeItemIndex, overItemIndex);
    const previousItem = newItems[overItemIndex - 1];
    const nextItem = newItems[overItemIndex + 1];

    const dragDepth = getDragDepth(dragOffset, indentationWidth);
    const projectedDepth = activeItem.depth + dragDepth;
    const currentMaxDepth = getMaxDepth({
        previousItem,
        activeItem,
        maxDepth,
    });
    const minDepth = getMinDepth({ nextItem });
    let depth = projectedDepth;

    if (projectedDepth >= currentMaxDepth) {
        depth = currentMaxDepth;
    } else if (projectedDepth < minDepth) {
        depth = minDepth;
    }

    const getParentId = () => {
        if (depth === 0 || !previousItem) {
            return null;
        }

        if (depth === previousItem.depth) {
            return previousItem.parentId;
        }

        if (depth > previousItem.depth) {
            return previousItem.id;
        }

        const newParent = newItems
            .slice(0, overItemIndex)
            .reverse()
            .find((item) => item.depth === depth)?.parentId;

        return newParent ?? null;
    };

    return { depth, maxDepth: currentMaxDepth, minDepth, parentId: getParentId() };
};

const flatten = (
    items: TreeItem[] = [],
    parentId: UniqueIdentifier | null = null,
    depth = 0,
    maxDepth: number,
): FlattenedTreeItem[] => {
    return items.reduce<FlattenedTreeItem[]>((acc, item, index) => {
        const hasChildren = depth < maxDepth && !!item?.children;
        const isExpanded = item.isExpanded ?? true;

        return [
            ...acc,
            { ...item, parentId, depth, index, hasChildren, isExpanded, data: { ...item } },
            ...flatten(item?.children ?? [], item.id, depth + 1, maxDepth),
        ];
    }, []);
};

export const flattenTree = (items: TreeItem[], maxDepth = MAX_DEFAULT_DEPTH): FlattenedTreeItem[] => {
    return flatten(items, null, 0, maxDepth);
};

export const findItem = (items: TreeItem[], itemId: UniqueIdentifier) => {
    return items.find(({ id }) => id === itemId);
};

export const buildTree = (flattenedTreeItems: FlattenedTreeItem[]): TreeItem[] => {
    const root: TreeItem = { id: 'root', children: [], hasChildren: false };
    const nodes: Record<string, TreeItem> = { [root.id]: root };
    const items = flattenedTreeItems.map((item) => ({ ...item, children: item.hasChildren ? [] : undefined }));

    for (const item of items) {
        const { id, children, data } = item;
        const parentId = item.parentId ?? root.id;
        const parent = nodes[parentId] ?? findItem(items, parentId);

        nodes[id] = { id, children };
        parent.children?.push({ ...data, children });
    }

    return root.children!;
};

export const findItemDeep = (items: TreeItem[], itemId: UniqueIdentifier): TreeItem | undefined => {
    for (const item of items) {
        const { id, children = [], hasChildren } = item;

        if (id === itemId) {
            return item;
        }

        if (hasChildren) {
            const child = findItemDeep(children, itemId);

            if (child) {
                return child;
            }
        }
    }

    return undefined;
};

export const setProperty = <T extends keyof TreeItem>(
    items: TreeItem[],
    id: UniqueIdentifier,
    property: T,
    setter: (value: TreeItem[T]) => TreeItem[T],
) => {
    for (const item of items) {
        if (item.id === id) {
            item[property] = setter(item[property]);
            continue;
        }

        if (item.children?.length) {
            item.children = setProperty(item.children, id, property, setter);
        }
    }

    return [...items];
};

export const removeChildrenOf = (items: FlattenedTreeItem[], ids: UniqueIdentifier[]) => {
    const excludeParentIds = [...ids];

    return items.filter((item) => {
        if (item.parentId && excludeParentIds.includes(item.parentId)) {
            if (item.hasChildren) {
                excludeParentIds.push(item.id);
            }
            return false;
        }

        return true;
    });
};

const countChildren = (items: TreeItem[], count = 0): number => {
    return items.reduce((acc, { children }) => {
        if (children?.length) {
            return countChildren(children, acc + 1);
        }

        return acc + 1;
    }, count);
};

export const getChildCount = (items: TreeItem[], id: UniqueIdentifier) => {
    const item = findItemDeep(items, id);

    return item ? countChildren(item.children ?? []) : 0;
};
