import { Select, SingleValue } from '@components/Select';
import {
    FilterOption,
    FilterOptionRadioOrCheckbox,
    FilterOptionSelect,
    FilterOptionSelectOption,
    FilterPropOptions,
} from '@components/Filter/types';
import { FilterOptionsProps } from '../..';

export type SelectOptionsProps = {
    filterOption: FilterOptionSelect;
    onChange: (filterChanges: FilterOptionSelect) => void;
    options: FilterPropOptions;
    filterOptions: (props: FilterOptionsProps) => JSX.Element | null;
};

export type Option = {
    label: string;
    value: string;
    checked: boolean;
    isDisabled: boolean;
    option?: FilterOptionRadioOrCheckbox;
};

export function SelectOptions({
    filterOption,
    onChange,
    options,
    filterOptions: RecursiveFilterOptions,
}: SelectOptionsProps) {
    const handleSelectChange = (newValue: SingleValue<Option>) => {
        onChange({
            ...filterOption,
            value: newValue?.value,
        });
    };

    const selectOptions: Option[] = filterOption.options?.map((option) => ({
        id: option.id,
        label: option.label || '',
        value: option.value ?? '',
        checked: option.checked || false,
        isDisabled: !option.editable || false,
        option: option.option,
    }));

    const optionSelected = selectOptions.find((option) => option.value === filterOption.value);

    const handleOptionChange = (newOption: FilterOption) => {
        if (optionSelected) {
            onChange({
                ...filterOption,
                options: filterOption.options.map((option) => {
                    if (option.value === optionSelected.value) {
                        return {
                            ...option,
                            option: newOption,
                        } as FilterOptionSelectOption;
                    }

                    return option;
                }),
            });
        }
    };

    return (
        <>
            <Select.Basic
                isSearchable
                placeholder={filterOption.label}
                noOptionsMessage={() => options.resources.noOptions ?? 'No options'}
                value={optionSelected}
                isDisabled={!filterOption.editable}
                onChange={handleSelectChange}
                options={selectOptions}
                styleGuide="new"
            />
            {optionSelected?.option && (
                <div className="mt-4">
                    <RecursiveFilterOptions
                        filterOption={optionSelected?.option}
                        onChange={handleOptionChange}
                        options={options}
                    />
                </div>
            )}
        </>
    );
}
