import {
    forwardRef,
    useRef,
    useImperativeHandle,
    type InputHTMLAttributes,
    type HTMLAttributes,
    type ElementRef,
    type ComponentPropsWithoutRef,
    type SyntheticEvent,
} from 'react';
import { useOnOutsideClick } from '@sidetalk/hooks';
import { Button } from '@components/Button';
import { Icon } from '@components/Icon';
import { cn } from '@helpers/index';
import { tv } from '@lib/tailwind-variants';
import { InlineEditContextProvider, InlineEditContextProviderProps, useInlineEdit } from './context';

const CustomRoot = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
    ({ className, ...rest }, forwardedRef) => {
        const { isEditing, keepFocusOnOutsideClick, handleOnOutsideClick } = useInlineEdit('Root');
        const containerRef = useRef<HTMLDivElement>(null);

        useImperativeHandle(forwardedRef, () => containerRef.current!, []);

        const shouldListForOutsideClick = !keepFocusOnOutsideClick && isEditing;
        useOnOutsideClick(shouldListForOutsideClick, containerRef, (event) =>
            handleOnOutsideClick(event as unknown as SyntheticEvent),
        );

        return <div ref={containerRef} className={cn('relative w-full min-w-32', className)} {...rest} />;
    },
);

type RootProps = HTMLAttributes<HTMLDivElement> & Partial<InlineEditContextProviderProps>;

const Root = forwardRef<HTMLDivElement, RootProps>(
    (
        {
            defaultValue = '',
            value = '',
            allowEmptyValue = false,
            keepFocusOnOutsideClick = false,
            disabled = false,
            onSave,
            onCancel,
            onRestore,
            onChange,
            onEdit,
            ...rest
        },
        forwardedRef,
    ) => {
        return (
            <InlineEditContextProvider
                defaultValue={defaultValue}
                value={value}
                allowEmptyValue={allowEmptyValue}
                keepFocusOnOutsideClick={keepFocusOnOutsideClick}
                disabled={disabled}
                onSave={onSave}
                onCancel={onCancel}
                onRestore={onRestore}
                onChange={onChange}
                onEdit={onEdit}
            >
                <CustomRoot ref={forwardedRef} {...rest} />
            </InlineEditContextProvider>
        );
    },
);

Root.displayName = 'InlineEdit.Root';

export type InlineEditRootProps = typeof Root;

const inputVariant = tv({
    base: [
        'rounded-xxs flex min-h-6 min-w-32 cursor-text items-center pl-1 outline-none',
        'hover:bg-coremedia-grey-100 placeholder:text-coremedia-grey-400',
        'ring-coremedia-blue-450 ring-offset-1',
    ],

    variants: {
        isEditing: {
            true: 'w-full bg-transparent ring-2',

            false: 'w-fit',
        },

        isInput: {
            true: '',
        },

        isPlaceholder: {
            true: '',
        },

        keepFocusOnOutsideClick: {
            true: '',
        },
    },

    compoundVariants: [
        {
            isEditing: true,
            keepFocusOnOutsideClick: true,
            className: 'ring-2',
        },
        {
            isInput: false,
            isPlaceholder: true,
            className: 'text-coremedia-grey-400',
        },
        {
            isEditing: false,
            isInput: true,
            className: 'sr-only',
        },
    ],

    defaultVariants: {
        isEditing: false,
        isInput: false,
        isPlaceholder: false,
        keepFocusOnOutsideClick: false,
    },
});

type InputProps = Omit<
    InputHTMLAttributes<HTMLInputElement>,
    'defaultValue' | 'value' | 'onChange' | 'onKeyDown' | 'onClick' | 'onFocus' | 'onBlur' | 'disabled'
>;

const Input = forwardRef<HTMLInputElement, InputProps>(
    ({ placeholder, className, style = {}, ...rest }, forwardedRef) => {
        const containerRef = useRef<HTMLDivElement>(null);

        const {
            inputValue,
            isEditing,
            keepFocusOnOutsideClick,
            handleOnChange,
            handleKeyDown,
            handleOnFocus,
            disabled,
        } = useInlineEdit('Input');

        const handleFocusInput = (e: SyntheticEvent) => {
            handleOnFocus(e);

            setTimeout(() => {
                (containerRef.current!.lastChild as HTMLInputElement).focus();
            }, 0);
        };

        // eslint-disable-next-line react-compiler/react-compiler
        const inputWidth = isEditing ? (containerRef.current?.clientWidth ?? 0) : 0;

        return (
            <div ref={containerRef}>
                {!isEditing && (
                    <div
                        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                        tabIndex={0}
                        onFocus={handleFocusInput}
                        className={inputVariant({
                            isEditing,
                            isPlaceholder: !inputValue,
                            className,
                        })}
                    >
                        {inputValue || placeholder}
                    </div>
                )}

                <input
                    ref={forwardedRef}
                    type="text"
                    value={inputValue}
                    onChange={handleOnChange}
                    onKeyDown={handleKeyDown}
                    onFocus={handleOnFocus}
                    disabled={disabled}
                    className={inputVariant({
                        isEditing,
                        isInput: true,
                        keepFocusOnOutsideClick,
                        className,
                    })}
                    placeholder={placeholder}
                    style={{
                        ...style,
                        // eslint-disable-next-line react-compiler/react-compiler
                        minWidth: `${inputWidth}px`,
                    }}
                    {...rest}
                />
            </div>
        );
    },
);

Input.displayName = 'InlineEdit.Input';

type ButtonsContainerProps = HTMLAttributes<HTMLDivElement>;

const ButtonsContainer = forwardRef<HTMLDivElement, ButtonsContainerProps>(({ className, ...rest }, forwardedRef) => {
    const { keepFocusOnOutsideClick, isEditing } = useInlineEdit('ButtonsContainer');

    return (
        <div
            ref={forwardedRef}
            className={cn(
                'absolute -bottom-1.5 -right-1 z-10 hidden translate-y-full gap-1',
                // remove important from `flex` when bootstrap is removed from Hera (class `hidden` has `!important`)
                isEditing && '!flex',
                keepFocusOnOutsideClick && isEditing && '!flex',
                className,
            )}
            {...rest}
        />
    );
});

ButtonsContainer.displayName = 'InlineEdit.ButtonsContainer';

type SaveButtonProps = ComponentPropsWithoutRef<typeof Button> & {
    label?: string;
};

const SaveButton = forwardRef<ElementRef<typeof Button>, SaveButtonProps>(
    ({ label = 'Save', className, children, ...rest }, forwardedRef) => {
        const { handleSaveButton } = useInlineEdit('SaveButton');

        return (
            <Button
                ref={forwardedRef}
                size="sm"
                variant="boxed"
                className={cn('border-coremedia-grey-300 shadow-lg', className)}
                onClick={handleSaveButton}
                {...rest}
            >
                {children ? children : <Icon type="byside" iconName="broadcast-ok" label={label} />}
            </Button>
        );
    },
);

SaveButton.displayName = 'InlineEdit.SaveButton';

type CancelButtonProps = ComponentPropsWithoutRef<typeof Button> & {
    label?: string;
};

const CancelButton = forwardRef<ElementRef<typeof Button>, CancelButtonProps>(
    ({ label = 'Cancel', className, children, ...rest }, forwardedRef) => {
        const { handleCancelButton } = useInlineEdit('CancelButton');

        return (
            <Button
                ref={forwardedRef}
                size="sm"
                variant="boxed"
                className={cn('border-coremedia-grey-300 shadow-lg', className)}
                onClick={handleCancelButton}
                {...rest}
            >
                {children ? children : <Icon type="byside" iconName="broadcast-error" label={label} />}
            </Button>
        );
    },
);

CancelButton.displayName = 'InlineEdit.CancelButton';

type RestoreButtonProps = ComponentPropsWithoutRef<typeof Button> & {
    label?: string;
};

const RestoreButton = forwardRef<ElementRef<typeof Button>, RestoreButtonProps>(
    ({ label = 'Restore', className, children, ...rest }, forwardedRef) => {
        const { handleRestoreButton } = useInlineEdit('RestoreButton');

        return (
            <Button
                ref={forwardedRef}
                size="sm"
                variant="boxed"
                className={cn('border-coremedia-grey-300 shadow-lg', className)}
                onClick={handleRestoreButton}
                {...rest}
            >
                {children ? children : <Icon type="byside" iconName="refresh" label={label} />}
            </Button>
        );
    },
);

RestoreButton.displayName = 'InlineEdit.RestoreButton';

type BasicOptions = {
    saveLabel?: string;
    cancelLabel?: string;
    restoreLabel?: string;
    inputProps?: ComponentPropsWithoutRef<typeof Input>;
};

type BasicProps = Omit<ComponentPropsWithoutRef<typeof Root>, 'children'> & {
    placeholder?: string;
    showControls?: boolean;
    showRestoreButton?: boolean;
    options?: BasicOptions;
};

const Basic = forwardRef<ElementRef<typeof Input>, BasicProps>(
    ({ placeholder, showControls = true, showRestoreButton = false, options, ...rest }, forwardedRef) => {
        return (
            <Root {...rest}>
                <Input ref={forwardedRef} placeholder={placeholder} {...options?.inputProps} />

                {showControls && (
                    <ButtonsContainer>
                        <SaveButton label={options?.saveLabel} />
                        <CancelButton label={options?.cancelLabel} />
                        {showRestoreButton && <RestoreButton label={options?.restoreLabel} />}
                    </ButtonsContainer>
                )}
            </Root>
        );
    },
);

Basic.displayName = 'InlineEdit.Basic';

export type InlineEditBasicProps = typeof Basic;

export const InlineEdit = {
    Root,
    Input,
    ButtonsContainer,
    SaveButton,
    CancelButton,
    RestoreButton,
    Basic,
};
