import { Button } from '@components/Button';
import { Icon } from '@components/Icon';
import { Popover } from '@components/Popover';
import { FilterOptions } from '../FilterOptions';
import { FilterPropOptions } from '../../types';
import { useFilter } from '../../context';
import { buttonsContainer, closeButton, closeButtonContainer, contentButtonsContainer, triggerButton } from '../styles';

type FilterToSaveProps = {
    isApplyDisabled: boolean;
    options: FilterPropOptions;
};

export function FilterToSave({ isApplyDisabled, options }: FilterToSaveProps) {
    const { resources } = options;

    const {
        filterState: { filterSelected, selectedConfig, isPopoverOpen },
        containerRef,
        handleSaveFilterSelected,
        handleRemoveFilterSelected,
        handleSelectedFilterChanges,
        handleOnOpenPopover,
    } = useFilter('FilterToSave');

    const shouldPopoverBeOpen = selectedConfig && selectedConfig.type !== 'empty' ? isPopoverOpen : false;
    const isButtonDisabled = selectedConfig && !selectedConfig.editable;

    return (
        <Popover.Root open={shouldPopoverBeOpen} onOpenChange={handleOnOpenPopover}>
            <div data-disabled={isButtonDisabled} className={buttonsContainer()}>
                <Popover.Trigger asChild disabled={isButtonDisabled}>
                    <button className={triggerButton()}>
                        <Icon type="bytalk" iconName="arrow-down" />
                        <span>{filterSelected?.label}</span>
                    </button>
                </Popover.Trigger>
                <div className={closeButtonContainer()}>
                    <button disabled={isButtonDisabled} onClick={handleRemoveFilterSelected} className={closeButton()}>
                        <Icon type="bytalk" iconName="close-details" label={resources.removeFilter} />
                    </button>
                </div>
            </div>
            <Popover.Content container={containerRef.current} styleGuide="new">
                <FilterOptions filterOption={selectedConfig} onChange={handleSelectedFilterChanges} options={options} />
                <div className={contentButtonsContainer()}>
                    <Popover.CloseButton asChild>
                        <Button styleGuide="new" variant="secondary">
                            {resources.cancel}
                        </Button>
                    </Popover.CloseButton>
                    <Button
                        styleGuide="new"
                        variant="tertiary"
                        onClick={handleSaveFilterSelected}
                        disabled={isApplyDisabled}
                    >
                        {resources.apply}
                    </Button>
                </div>
            </Popover.Content>
        </Popover.Root>
    );
}
