import { coremediaColors } from '@sidetalk/tokens';
import { Select } from '@components/Select';
import { useQueryBuilderState } from '../../context/QueryBuilder';
import type { CombinatorSelectorProps, Combinator } from 'react-querybuilder';

const customSelectStyles = {
    container: () => ({
        backgroundColor: coremediaColors.white,
        width: '100%',
    }),
    singleValue: () => ({
        overflow: 'unset',
    }),
};

export function CombinatorSelector({ options, value, rules, handleOnChange }: CombinatorSelectorProps) {
    const { state } = useQueryBuilderState();
    const { resources, disabled } = state;

    const combinators = options.map((opt) => ({ value: (opt as Combinator<string>).name, label: opt.label }));

    return (
        <div className="absolute top-[15px] w-[55px]">
            {rules && rules.length > 0 && (
                <Select.Basic
                    styleGuide="coremedia"
                    styles={customSelectStyles}
                    name="combinator"
                    options={combinators}
                    menuPortalTarget={document.body}
                    isSearchable={false}
                    defaultValue={combinators.find((o) => o.value === value) ?? combinators[0]}
                    onChange={(chosen) => handleOnChange(chosen?.value)}
                    menuPlacement="auto"
                    noOptionsMessage={() => resources?.no_options ?? 'No options'}
                    isDisabled={disabled}
                />
            )}
        </div>
    );
}
