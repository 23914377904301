import {
    forwardRef,
    type AnchorHTMLAttributes,
    type ButtonHTMLAttributes,
    type ComponentPropsWithRef,
    type OlHTMLAttributes,
} from 'react';
import { tv } from '@lib/tailwind-variants';
import { BreadcrumbsContextProvider, BreadcrumbsContextProviderProps, useBreadcrumbs } from './context';

const breadcrumbSlots = tv({
    slots: {
        ol: 'flex items-center gap-4',
        li: [
            'flex cursor-default items-center gap-4',
            'after:block after:size-2 after:-rotate-45 last-of-type:after:content-[]',
            'border- after:border-b-2 after:border-r-2 after:border-solid after:border-red-500',
        ],
        link: [
            'relative cursor-pointer text-sm font-semibold text-black transition-colors',
            'disabled:cursor-default disabled:text-black',
            'hover:text-red-500 focus-visible:text-red-500',
        ],
    },
});

const { ol, li, link } = breadcrumbSlots();

type CustomRootProps = OlHTMLAttributes<HTMLOListElement> & BreadcrumbsContextProviderProps;

const Root = forwardRef<HTMLOListElement, CustomRootProps>(({ disabled = false, className, ...rest }, forwardedRef) => (
    <BreadcrumbsContextProvider disabled={disabled}>
        <nav aria-label="Breadcrumb">
            <ol ref={forwardedRef} className={ol({ className })} {...rest} />
        </nav>
    </BreadcrumbsContextProvider>
));

Root.displayName = 'Breadcrumbs.Root';

export type BreadcrumbsRootProps = ComponentPropsWithRef<typeof Root>;

type CustomPageLinkProps = AnchorHTMLAttributes<HTMLAnchorElement> & {
    isCurrentPage?: boolean;
};

const PageLink = forwardRef<HTMLAnchorElement, CustomPageLinkProps>(
    ({ children, 'aria-current': ariaCurrent, isCurrentPage = false, className, ...rest }, forwardedRef) => {
        const ariaCurrentValue = isCurrentPage ? 'page' : ariaCurrent;

        return (
            <li className={li({ className })}>
                <a ref={forwardedRef} className={link()} {...rest} aria-current={ariaCurrentValue}>
                    {children}
                </a>
            </li>
        );
    },
);

PageLink.displayName = 'Breadcrumbs.PageLink';

type CustomPageButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
    isCurrentPage?: boolean;
};

const PageButton = forwardRef<HTMLButtonElement, CustomPageButtonProps>(
    (
        { children, 'aria-current': ariaCurrent, isCurrentPage = false, disabled = false, className, ...rest },
        forwardedRef,
    ) => {
        const ariaCurrentValue = isCurrentPage ? 'page' : ariaCurrent;

        const { isDisabled } = useBreadcrumbs('PageButton');

        return (
            <li className={li({ className })}>
                <button
                    type="button"
                    ref={forwardedRef}
                    disabled={disabled || isDisabled}
                    className={link()}
                    {...rest}
                    aria-current={ariaCurrentValue}
                >
                    {children}
                </button>
            </li>
        );
    },
);

PageButton.displayName = 'Breadcrumbs.PageButton';

export const Breadcrumbs = {
    Root,
    PageLink,
    PageButton,
};
