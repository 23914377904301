import { ComponentPropsWithoutRef, ElementRef, forwardRef, lazy, Suspense, useMemo, useCallback } from 'react';
import DOMPurify, { type Config } from 'dompurify';
import { Loader } from '@components/Loader';
import { cn } from '@helpers/index';
import { AdditionalTags, JoditOptions } from './types';
import { getFullSanitizeConfig, sanitizeJoditEditorContent } from './helpers';
import type { Jodit } from 'jodit';

export type EditorJodit = Jodit;

const JoditEditor = lazy(async () => ({ default: (await import('./components/Input')).Input }));

const DEFAULT_BUTTONS = [
    'source',
    '|',
    'bold',
    'strikethrough',
    'underline',
    'italic',
    'eraser',
    '|',
    'ul',
    'ol',
    '|',
    'outdent',
    'indent',
    '|',
    'font',
    'fontsize',
    'brush',
    'paragraph',
    'align',
    '|',
    'table',
    'link',
];

type RootProps = ComponentPropsWithoutRef<typeof JoditEditor> &
    Pick<HTMLTextAreaElement, 'id' | 'name'> & {
        readOnly?: boolean;
        editorRef?: (instance: EditorJodit | null) => void;
        customConfig?: JoditOptions;
        sanitizeConfig?: Config;
        additionalTags?: AdditionalTags;
    };

const Root = forwardRef<ElementRef<typeof JoditEditor>, RootProps>(
    (
        {
            value,
            defaultValue,
            readOnly = false,
            customConfig,
            sanitizeConfig,
            additionalTags = ['iframe'],
            className,
            ...rest
        },
        forwardedRef,
    ) => {
        const config = useMemo(
            () =>
                ({
                    readonly: readOnly,
                    buttons: DEFAULT_BUTTONS,
                    buttonsMD: DEFAULT_BUTTONS,
                    buttonsSM: DEFAULT_BUTTONS,
                    buttonsXS: DEFAULT_BUTTONS,
                    hidePoweredByJodit: true,
                    beautifyHTML: false, // do not request external resources
                    sourceEditor: 'area', // do not request external resources
                    ...customConfig,
                }) as JoditOptions,
            [readOnly, customConfig],
        );

        const sanitizeValue = useCallback(
            (value?: string): string | undefined =>
                typeof value === 'string'
                    ? (DOMPurify.sanitize(
                          sanitizeJoditEditorContent(value),
                          getFullSanitizeConfig(sanitizeConfig, additionalTags ?? []),
                      ) as unknown as string)
                    : undefined,
            [sanitizeConfig, additionalTags],
        );

        const sanitizedValue = useMemo(() => sanitizeValue(value), [sanitizeValue, value]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        const sanitizedDefaultValue = useMemo(() => sanitizeValue(defaultValue), []);

        return (
            <Suspense fallback={<Loader.Byside />}>
                <JoditEditor
                    ref={forwardedRef}
                    value={sanitizedValue}
                    defaultValue={sanitizedDefaultValue}
                    config={config}
                    {...rest}
                    sanitizeValue={sanitizeValue}
                    className={cn('[&_.jodit-status-bar-link]:hidden', className)}
                />
            </Suspense>
        );
    },
);

Root.displayName = 'Editor.Root';

export type EditorRootProps = ComponentPropsWithoutRef<typeof Root>;

export const Editor = {
    Root,
};
