import { ComponentPropsWithRef, forwardRef, HTMLAttributes } from 'react';
import {
    LeftColumnItemProps,
    Scheduler,
    SchedulerProps,
    LeftColumn,
    SchedulerProjectData,
} from '@react-scheduler/react-scheduler';
import { useHasOverflowed } from '@sidetalk/hooks';
import { isFunction } from '@sidetalk/helpers';
import { Tooltip } from '@components/Tooltip';

const CustomRoot = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
    ({ className, ...rest }, forwardedRef) => <div ref={forwardedRef} className={className} {...rest} />,
);

CustomRoot.displayName = 'ReactScheduler.Root';

function TileContent({ data }: { data: SchedulerProjectData }) {
    const [titleRef, hasOverflowed] = useHasOverflowed<HTMLDivElement>();

    return (
        <Tooltip.Basic content={hasOverflowed ? data.title : undefined}>
            <div ref={titleRef} className="sticky left-[172px] overflow-hidden truncate">
                <div className="m-0 inline truncate p-0 font-normal">{data.title}</div>
            </div>
        </Tooltip.Basic>
    );
}

function LeftColumnItem({ id, item, rows, onItemClick }: LeftColumnItemProps) {
    const [titleRef, hasOverflowed] = useHasOverflowed<HTMLDivElement>();

    return (
        <LeftColumn.Wrapper
            clickable={isFunction(onItemClick)}
            rows={rows}
            onClick={() => onItemClick?.({ id, label: item })}
            ref={titleRef}
            className="[&_p]:focus:!text-coremedia-blue-600 [&_p]:hover:!text-coremedia-blue-600 [&_p]:hover:!font-semibold [&_p]:focus:!font-semibold"
        >
            <LeftColumn.InnerWrapper className="h-full">
                <LeftColumn.TextWrapper className="gap-1">
                    {item.type && (
                        <LeftColumn.Text
                            className="font-default !border-coremedia-blue-600 !text-coremedia-blue-600 text-[0.421rem]"
                            isDistribution
                        >
                            {item?.type ?? 'Distribution'}
                        </LeftColumn.Text>
                    )}
                    <Tooltip.Basic content={hasOverflowed ? item.title : undefined}>
                        <LeftColumn.Text className="font-default h-[18px] place-content-center !pr-3" isMain>
                            {item.title}
                        </LeftColumn.Text>
                    </Tooltip.Basic>
                </LeftColumn.TextWrapper>
            </LeftColumn.InnerWrapper>
        </LeftColumn.Wrapper>
    );
}

TileContent.displayName = 'TileContent';

LeftColumnItem.displayName = 'LeftColumnItem';

function HeaderComponent() {
    return <div className="font-default h-[18px] pl-4 text-xs font-bold">Skills</div>;
}

HeaderComponent.displayName = 'HeaderComponent';

export const ReactSchedulerBasic = forwardRef<HTMLDivElement, SchedulerProps>((props, forwardedRef) => {
    return (
        <CustomRoot ref={forwardedRef}>
            <Tooltip.Provider>
                <Scheduler
                    config={{ zoom: 2, showTooltip: false }}
                    HeaderComponent={HeaderComponent}
                    LeftColumnItemComponent={LeftColumnItem}
                    TileContentComponent={TileContent}
                    {...props}
                />
            </Tooltip.Provider>
        </CustomRoot>
    );
});

export type ReactSchedulerProps = ComponentPropsWithRef<typeof ReactSchedulerBasic>;

ReactSchedulerBasic.displayName = 'ReactScheduler';

export const ReactScheduler = {
    Basic: ReactSchedulerBasic,
    Root: CustomRoot,
    HeaderComponent: HeaderComponent,
    LeftColumnItem: LeftColumnItem,
    TileContent: TileContent,
    Scheduler: Scheduler,
};
