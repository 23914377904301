import { Button } from '@components/Button';
import { cn } from '@helpers/index';
import { Icon } from '@components/Icon';
import { useQueryBuilderState } from '../../context/QueryBuilder';
import type { ActionProps } from 'react-querybuilder';

export function RemoveRule({ label, handleOnClick }: ActionProps) {
    const { state } = useQueryBuilderState();
    const { disabled } = state;

    return (
        <Button
            className={cn(
                'flex w-[26px] shrink-0 grow-0 items-center justify-end p-0 text-[large]',
                disabled
                    ? 'hover:!text-gray-250 text-gray-250 cursor-not-allowed'
                    : 'enabled:hover:!text-coremedia-red-500 cursor-pointer',
            )}
            onClick={disabled ? undefined : handleOnClick}
            title={label as string}
            styleGuide="coremedia"
            variant="icon"
        >
            <Icon type="byside" iconName="trash" />
        </Button>
    );
}
