import { SingleValue, StylesConfig } from 'react-select';
import { SelectOption } from '@components/Select';

export const customAddFilterSelectStyles: StylesConfig<SingleValue<SelectOption>, false> = {
    control: () => ({
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        borderRadius: '4px',
        padding: '0 1rem',
        fontSize: '0.75rem',
        fontWeight: '600',
        lineHeight: '160%',
        height: '2.25rem',
        minHeight: '2.25rem',
        gap: '0.25rem',
        backgroundColor: '#f2f2f2',
        border: '1px solid #e5e5e5',
        color: '#262626',
        minWidth: 'fit-content',
        '&:hover': {
            borderColor: '#f95d50',
        },
        outline: 'none',
    }),
    placeholder: () => ({
        color: '#999999',
    }),
    indicatorsContainer: () => ({
        display: 'none',
    }),
    menuList: () => ({
        width: 'fit-content',
        minWidth: '10rem',
    }),
};
