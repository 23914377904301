import { ComponentPropsWithoutRef, ElementRef, HTMLAttributes, LabelHTMLAttributes, forwardRef } from 'react';
import { Root, Indicator } from '@radix-ui/react-checkbox';
import { DividerHorizontalIcon, CheckIcon } from '@radix-ui/react-icons';
import { VariantProps, tv } from '@lib/tailwind-variants';
import { CheckboxContextProvider, useCheckbox } from './context';

const rootVariants = tv({
    base: 'flex items-center gap-1',

    variants: {
        size: {
            sm: 'leading-[14px]',
            md: 'leading-4',
        },
    },

    defaultVariants: {
        size: 'md',
    },
});

type CustomRootProps = HTMLAttributes<HTMLDivElement> & VariantProps<typeof rootVariants>;

export const CustomRoot = forwardRef<HTMLDivElement, CustomRootProps>(({ className, size, ...rest }, forwardedRef) => (
    <CheckboxContextProvider>
        <div ref={forwardedRef} className={rootVariants({ size, className })} {...rest} />
    </CheckboxContextProvider>
));

CustomRoot.displayName = 'Checkbox.Root';

const checkboxInputVariants = tv({
    base: [
        'relative m-1 box-border flex items-center justify-center p-0',
        'rounded-xxs border border-solid border-gray-400 outline-none',
        'cursor-pointer overflow-hidden bg-transparent leading-none',
        'disabled:!border-gray-100 disabled:data-[state=checked]:!bg-gray-100 disabled:data-[state=indeterminate]:!bg-gray-100',
        'focus:outline-offset-3 focus:outline-coremedia-blue-500 focus:outline-1',
    ],

    variants: {
        size: {
            sm: 'size-[14px] text-sm',

            md: 'text-md size-4',
        },

        hasError: {
            true: [
                'data-[state=checked]:border-red-500 data-[state=checked]:bg-red-500',
                'data-[state=indeterminate]:border-red-500 data-[state=indeterminate]:bg-red-500',
            ],

            false: [
                'data-[state=checked]:border-green-500 data-[state=checked]:bg-green-500',
                'data-[state=indeterminate]:border-green-500 data-[state=indeterminate]:bg-green-500',
            ],
        },
    },

    defaultVariants: {
        size: 'md',
        hasError: false,
    },
});

const checkboxIndicatorVariants = tv({
    base: [
        'text-md group flex items-center justify-center text-white *:hidden',
        '[&>svg:last-child]:data-[state=checked]:block',
        '[&>svg:first-child]:data-[state=indeterminate]:block',
    ],

    variants: {
        size: {
            sm: 'size-[12px] text-sm',

            md: 'text-md size-[14px]',
        },
    },

    defaultVariants: {
        size: 'md',
    },
});

type CheckboxInputProps = ComponentPropsWithoutRef<typeof Root> & VariantProps<typeof checkboxInputVariants>;

export const CustomInput = forwardRef<ElementRef<typeof Root>, CheckboxInputProps>(
    ({ className, hasError, size, ...rest }, forwardedRef) => {
        const { randomCheckboxId } = useCheckbox('Input');

        return (
            <Root
                ref={forwardedRef}
                className={checkboxInputVariants({ hasError, size, className })}
                id={randomCheckboxId}
                {...rest}
            >
                <Indicator className={checkboxIndicatorVariants({ size })}>
                    <DividerHorizontalIcon />
                    <CheckIcon />
                </Indicator>
            </Root>
        );
    },
);

CustomInput.displayName = 'Checkbox.Input';

const labelVariants = tv({
    base: 'text-coremedia-grey-700 mb-0 cursor-pointer select-none font-medium tracking-normal',

    variants: {
        size: {
            xs: 'text-xs',

            sm: 'text-sm',

            md: 'text-md',
        },
    },

    defaultVariants: {
        size: 'sm',
    },
});

type LabelProps = LabelHTMLAttributes<HTMLLabelElement> & VariantProps<typeof labelVariants>;

export const CustomLabel = forwardRef<HTMLLabelElement, LabelProps>(({ className, size, ...rest }, forwardedRef) => {
    const { randomCheckboxId } = useCheckbox('Input');

    return (
        <label ref={forwardedRef} htmlFor={randomCheckboxId} className={labelVariants({ size, className })} {...rest} />
    );
});

CustomLabel.displayName = 'Checkbox.Label';
