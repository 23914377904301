import { Checkbox } from '@components/Checkbox';
import {
    FilterOption,
    FilterOptionInput,
    FilterOptionEmptyOrDate,
    FilterOptionRadioOrCheckbox,
    FilterPropOptions,
} from '@components/Filter/types';
import { FilterOptionsProps } from '../..';

export type CheckboxOptionsProps = {
    filterOption: FilterOptionRadioOrCheckbox;
    onChange: (filterChanges: FilterOptionRadioOrCheckbox) => void;
    filterOptions: (props: FilterOptionsProps) => JSX.Element | null;
    options: FilterPropOptions;
};

export function CheckboxOptions({
    filterOption,
    onChange,
    filterOptions: RecursiveFilterOptions,
    options,
}: CheckboxOptionsProps) {
    const handleCheckboxChange = (checked: boolean, checkboxIndex: number) => {
        const newOptions = filterOption.options.map((option, optionIndex) => {
            if (optionIndex === checkboxIndex) {
                if (option.options !== undefined) {
                    return {
                        ...option,
                        checked,
                        options: option.options.map((insideOption) => ({ ...insideOption, checked })),
                    };
                }

                return {
                    ...option,
                    checked,
                };
            }

            return option;
        });

        const hasOptionChecked = newOptions.some((option) => option.checked);

        const newFilterOption = {
            ...filterOption,
            options: newOptions,
            checked: hasOptionChecked,
        };

        onChange(newFilterOption);
    };

    const handleOptionChange = (newOption: FilterOption, checkboxIndex: number, optionIndex: number) => {
        const newFilterOption = {
            ...filterOption,
            options: filterOption.options.map((checkboxOption, filterCheckboxIndex) => {
                if (filterCheckboxIndex === checkboxIndex) {
                    const newCheckboxOption = {
                        ...checkboxOption,
                        options: checkboxOption.options?.map((option, index) => {
                            if (optionIndex === index) {
                                return {
                                    ...newOption,
                                    checked: true,
                                } as FilterOptionInput | FilterOptionEmptyOrDate;
                            }

                            return {
                                ...option,
                                checked: option.value !== '',
                            };
                        }),
                    };

                    return newCheckboxOption;
                }

                return checkboxOption;
            }),
        };

        onChange(newFilterOption);
    };

    const checkboxOptions = filterOption.options.map((option) => ({
        id: option.id,
        label: option.label || '',
        value: option.value ?? '',
        checked: option.checked || false,
        disabled: !option.editable || false,
        options: option.options?.map((optionsOption) => ({ ...optionsOption, value: optionsOption.value ?? '' })),
    }));

    return (
        <div className="flex flex-col">
            {checkboxOptions.map((checkbox, checkboxIndex) => (
                <div key={checkbox.id ?? checkbox.value}>
                    <Checkbox.Root>
                        <Checkbox.Input
                            checked={checkbox.checked}
                            disabled={checkbox.disabled}
                            onCheckedChange={(checked) => handleCheckboxChange(checked as boolean, checkboxIndex)}
                        />
                        <Checkbox.Label>{checkbox.label}</Checkbox.Label>
                    </Checkbox.Root>
                    {filterOption.checked && checkbox.checked && !!checkbox.options?.length && (
                        <div className="mb-2 w-full pl-6 pt-2">
                            {checkbox.options.map((option, optionIndex) => (
                                <RecursiveFilterOptions
                                    key={`${checkbox.id ?? checkbox.value}${option.label}`}
                                    filterOption={option as FilterOptionInput | FilterOptionEmptyOrDate}
                                    onChange={(filterChanges) =>
                                        handleOptionChange(filterChanges, checkboxIndex, optionIndex)
                                    }
                                    options={options}
                                />
                            ))}
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
}
