import { ComponentPropsWithoutRef, ElementRef, forwardRef, useMemo } from 'react';
import { colors } from '@sidetalk/tokens';
import { CustomFallback, CustomImage, CustomRoot } from './styles';

type CustomAvatarProps = ComponentPropsWithoutRef<typeof CustomRoot> & {
    imageUrl?: string;
    name: string;
};

function invertColor(hex: string) {
    let newHex = hex;

    if (newHex.startsWith('#')) {
        newHex = newHex.slice(1);
    }

    // convert 3-digit hex to 6-digits.
    if (newHex.length === 3) {
        newHex = newHex[0] + newHex[0] + newHex[1] + newHex[1] + newHex[2] + newHex[2];
    }

    if (newHex.length !== 6) {
        throw new Error('Invalid HEX color.');
    }

    const r = parseInt(newHex.slice(0, 2), 16);
    const g = parseInt(newHex.slice(2, 4), 16);
    const b = parseInt(newHex.slice(4, 6), 16);

    return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? colors.gray850 : colors.white;
}

const COLORS = [colors.purple500, colors.blue500, colors.blue600, colors.turquoise500, colors.yellow500, colors.red500];

const Basic = forwardRef<ElementRef<typeof CustomRoot>, CustomAvatarProps>(
    ({ imageUrl, name, style, ...rest }, forwardedRef) => {
        const fallbackName = useMemo(
            () =>
                name
                    .trim()
                    .split(' ')
                    .map((word) => word[0])
                    .filter((_, i, array) => i === 0 || i === array.length - 1)
                    .join(''),
            [name],
        );

        const [randomBackgroundColor, textColor] = useMemo(() => {
            const randomBackgroundColor = COLORS[Math.floor(Math.random() * COLORS.length)];
            const textColor = invertColor(randomBackgroundColor);

            return [randomBackgroundColor, textColor];
        }, []);

        return (
            <CustomRoot
                ref={forwardedRef}
                style={{
                    backgroundColor: randomBackgroundColor,
                    color: textColor,
                    ...style,
                }}
                {...rest}
            >
                <CustomImage src={imageUrl} alt={name} />
                <CustomFallback delayMs={600}>{fallbackName}</CustomFallback>
            </CustomRoot>
        );
    },
);

export type AvatarBasicProps = typeof Basic;

export const Avatar = {
    Root: CustomRoot,
    Image: CustomImage,
    Fallback: CustomFallback,
    Basic,
};

Basic.displayName = 'Avatar.Basic';
