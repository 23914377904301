import { useCallback, useMemo } from 'react';
import { isSameYear, setMonth, startOfMonth } from 'date-fns';
import { useDayPicker, useNavigation } from 'react-day-picker';
import { capitalizeFirstLetter } from '@sidetalk/helpers';
import { Select, SelectOption, SingleValue } from '@components/Select';
import { DatePickerVariants } from '@components/DatePicker/types';

type MonthsDropdownProps = DatePickerVariants & {
    displayMonth: Date;
};

export function MonthsDropdown({ displayMonth, styleGuide }: MonthsDropdownProps) {
    const {
        fromDate,
        toDate,
        locale,
        formatters: { formatMonthCaption },
        labels: { labelMonthDropdown },
    } = useDayPicker();
    const { goToMonth } = useNavigation();

    const dropdownMonthsOptions: SelectOption[] = useMemo(() => {
        if (!fromDate || !toDate) {
            return [];
        }

        const dropdownMonths: SelectOption[] = [];

        if (isSameYear(fromDate, toDate)) {
            // only display the months included in the range
            const date = startOfMonth(fromDate);
            for (let month = fromDate.getMonth(); month <= toDate.getMonth(); month += 1) {
                const monthAsDate = setMonth(date, month);

                dropdownMonths.push({
                    label: capitalizeFirstLetter(formatMonthCaption(monthAsDate, { locale }) as string),
                    value: String(monthAsDate.getMonth()),
                });
            }
        } else {
            // display all the 12 months
            const date = startOfMonth(new Date()); // Any date should be OK, as we just need the year
            for (let month = 0; month <= 11; month += 1) {
                const monthAsDate = setMonth(date, month);

                dropdownMonths.push({
                    label: capitalizeFirstLetter(formatMonthCaption(monthAsDate, { locale }) as string),
                    value: String(monthAsDate.getMonth()),
                });
            }
        }

        return dropdownMonths;
    }, [fromDate, toDate, formatMonthCaption, locale]);

    const handleMonthChange = useCallback(
        (e: SingleValue<SelectOption>) => {
            const selectedMonth = Number(e?.value);
            const newMonth = setMonth(startOfMonth(displayMonth), selectedMonth);
            goToMonth(newMonth);
        },
        [displayMonth, goToMonth],
    );

    // Dropdown should appear only when both from/toDate is set
    if (!fromDate || !toDate) {
        return null;
    }

    const currentValue = dropdownMonthsOptions.find((option) => option.value === String(displayMonth.getMonth()));

    return (
        <Select.Basic
            aria-label={labelMonthDropdown()}
            options={dropdownMonthsOptions}
            value={currentValue}
            onChange={handleMonthChange}
            styleGuide={styleGuide}
        />
    );
}
