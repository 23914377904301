/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { KeyboardEvent, useEffect } from 'react';
import { useQueryBuilderState } from '../../context/QueryBuilder';
import { Between, Creator, Custom, BooleanSelector, DateRange } from '../Inputs';
import type { ValueEditorProps } from 'react-querybuilder';

type ValueEditorTypeCustomType =
    | 'boolean'
    | 'custom'
    | 'daterange'
    | 'creator'
    | 'number'
    | 'text'
    | 'select'
    | 'checkbox'
    | 'radio'
    | null;

export function ValueEditor({ fieldData: metaData, value, handleOnChange, operator }: ValueEditorProps) {
    const { state } = useQueryBuilderState();
    const { disabled } = state;

    useEffect(() => {
        if (['null', 'notNull', 'empty', 'notEmpty'].includes(operator)) {
            handleOnChange('');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [operator]);

    if (['null', 'notNull', 'empty', 'notEmpty'].includes(operator) || metaData?.valueEditorType === null) {
        return null;
    }

    if ((metaData?.valueEditorType as ValueEditorTypeCustomType) === 'boolean') {
        return (
            <div className="value-selector flex-auto">
                <BooleanSelector className="value-selector" value={value} handleOnChange={handleOnChange} />
            </div>
        );
    }

    if ((metaData?.valueEditorType as ValueEditorTypeCustomType) === 'custom') {
        return (
            <div className="value-selector flex-auto">
                <Custom
                    className="value-selector"
                    value={value}
                    handleOnChange={handleOnChange}
                    fieldId={metaData.value}
                />
            </div>
        );
    }

    if ((metaData?.valueEditorType as ValueEditorTypeCustomType) === 'daterange') {
        return (
            <div className="value-selector flex-auto">
                <DateRange value={value} handleOnChange={handleOnChange} />
            </div>
        );
    }

    if ((metaData?.valueEditorType as ValueEditorTypeCustomType) === 'creator') {
        return (
            <div className="value-selector flex-auto">
                <Creator className="value-selector" value={value} handleOnChange={handleOnChange} />
            </div>
        );
    }

    if (['in', 'notIn'].includes(operator)) {
        return (
            <div className="value-selector flex-auto">
                <Between className="value-selector" value={value} handleOnChange={handleOnChange} />
            </div>
        );
    }

    const sanitizeInputValue = (event: KeyboardEvent<HTMLInputElement>) => {
        if (
            (metaData?.valueEditorType as ValueEditorTypeCustomType) === 'number' &&
            event.code !== 'Backspace' && // allow backspace
            event.code !== 'Delete' && // allow delete
            Number.isNaN(Number(event.key))
        ) {
            event.preventDefault();
        }
    };

    return (
        <div className="value-selector flex-auto">
            <input
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                name={`query-builder-rule-field-${metaData?.valueEditorType}`}
                onChange={(e) => handleOnChange(e?.target?.value)}
                className="query-value-editor"
                value={value}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                type={metaData?.valueEditorType}
                onKeyDown={sanitizeInputValue}
                disabled={disabled}
            />
        </div>
    );
}
