import {
    ButtonHTMLAttributes,
    ComponentPropsWithoutRef,
    ElementRef,
    forwardRef,
    HTMLAttributes,
    HtmlHTMLAttributes,
    MutableRefObject,
} from 'react';
import { cn } from '@helpers/index';
import { Badge } from '@components/Badge';
import { Icon } from '@components/Icon';
import { useDraggableList } from './contexts/draggableList';
import { useDraggableItem } from './contexts/draggableItem';

export const DraggableButton = forwardRef<HTMLButtonElement, ButtonHTMLAttributes<HTMLButtonElement>>(
    ({ className, children, ...rest }, forwardedRef) => {
        const { disabled } = useDraggableList('DraggaleButton');

        return (
            <button
                ref={forwardedRef}
                disabled={disabled}
                className={cn(
                    [
                        'text-coremedia-grey-300 text-md mr-5 flex items-center p-1 transition-colors',
                        'outline-coremedia-blue-500 focus-visible:outline-2 focus-visible:outline-offset-2',
                        'aria-[disabled=false]:hover:text-coremedia-grey-400 aria-[disabled=true]:cursor-default',
                    ],
                    className,
                )}
                {...rest}
            >
                {children ?? <Icon type="byside" iconName="filter" />}
            </button>
        );
    },
);

DraggableButton.displayName = 'DraggableList.DraggableButton';

export const CollapseButton = forwardRef<HTMLElement, HtmlHTMLAttributes<HTMLElement>>(
    ({ className, children, ...rest }, forwardedRef) => {
        const { allowCollapse, handleCollapse } = useDraggableList('CollapseButton');
        const { element } = useDraggableItem('CollapseButton');

        if (!element.hasChildren) {
            return null;
        }

        if (!allowCollapse) {
            return (
                children ?? (
                    <Icon
                        ref={forwardedRef}
                        data-testid="draggable-item-value-folder"
                        type="byside"
                        iconName="folder"
                        className={cn('mr-2.5 font-normal', className)}
                        {...rest}
                    />
                )
            );
        }

        return (
            <button
                ref={forwardedRef as MutableRefObject<HTMLButtonElement>}
                type="button"
                onClick={() => handleCollapse(element.id)}
                className={cn('group/collapse-button outline-coremedia-blue-500 mr-2.5 flex p-2', className)}
                aria-expanded={element.isExpanded}
                data-state={element.isExpanded ? 'open' : 'close'}
                {...rest}
            >
                {children ?? (
                    <Icon
                        type="byside"
                        iconName="arrow-small"
                        className={cn([
                            '-rotate-90 text-[0.5rem] font-semibold transition-transform will-change-transform',
                            'group-data-[state=open]/collapse-button:rotate-0',
                        ])}
                    />
                )}
            </button>
        );
    },
);

CollapseButton.displayName = 'DraggableList.CollapseButton';

export const ActionButton = forwardRef<HTMLButtonElement, ButtonHTMLAttributes<HTMLButtonElement>>(
    ({ className, ...rest }, forwardedRef) => {
        const { disabled } = useDraggableList('DraggaleButton');

        return (
            <button
                type="button"
                ref={forwardedRef}
                disabled={disabled}
                className={cn(
                    [
                        'text-coremedia-grey-400 w-10 py-1 transition-colors',
                        'outline-coremedia-blue-500 focus-visible:outline-2 focus-visible:outline-offset-2',
                        'data-[selected=true]:text-coremedia-turquoise-500',
                        'enabled:hover:text-coremedia-grey-500 enabled:data-[selected=true]:hover:text-coremedia-turquoise-600',
                        'enabled:cursor-pointer disabled:cursor-not-allowed',
                        'data-[is-bold=true]:font-bold data-[is-italic=true]:italic data-[is-underline]:underline',
                    ],
                    className,
                )}
                {...rest}
            />
        );
    },
);

ActionButton.displayName = 'DraggableList.ActionButton';

export const ChildCountBadge = forwardRef<
    ElementRef<typeof Badge.Visual>,
    ComponentPropsWithoutRef<typeof Badge.Visual>
>((props, forwardedRef) => {
    const { childCount, element } = useDraggableItem('ChildCountBadge');

    if (!element.hasChildren) {
        return null;
    }

    return <Badge.Visual ref={forwardedRef} number={childCount} {...props} />;
});

ChildCountBadge.displayName = 'DraggableList.ChildCountBadge';

export const EmptyElementsMessageContainer = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
    ({ className, children, ...rest }, forwardedRef) => {
        const {
            flattenedItems,
            resources: { emptyElements },
        } = useDraggableList('EmptyElementsMessageContainer');

        if (flattenedItems.length) {
            return null;
        }

        return (
            <div
                ref={forwardedRef}
                className={cn(
                    ['text-coremedia-grey-400 bg-coremedia-white h-16 p-6', 'border-b-coremedia-grey-200 border-b'],
                    className,
                )}
                {...rest}
            >
                {children ?? emptyElements}
            </div>
        );
    },
);

EmptyElementsMessageContainer.displayName = 'DraggableList.EmptyElementsMessage';
