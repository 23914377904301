import { ButtonHTMLAttributes, forwardRef } from 'react';
import { VariantProps, tv } from '@lib/tailwind-variants';
import { buttonVariants } from '@components/Button';

export const pickerButtonVariants = tv({
    extend: buttonVariants,
    base: 'w-44 justify-between [&_div]:flex [&_div]:gap-3',

    variants: {
        styleGuide: {
            coremedia: [
                'bg-coremedia-blue-500 text-coremedia-white h-[22px] w-auto rounded-full px-[15px] text-[11px] !outline-none',
                '[&_i]:ml-[5px] [&_i]:!h-auto [&_i]:!w-auto [&_i]:!p-0 [&_i]:!text-[7px] [&_i]:font-bold',
            ],

            new: '[&_i]:!text-md !px-4 !pr-2',
        },

        isPopoverWithInput: {
            true: [
                'enabled:focus-within:outline-solid enabled:focus-within:outline-coremedia-blue-500 enabled:focus-within:outline-1',
                '[&_input]:size-full [&_input]:border-0 [&_input]:bg-transparent [&_input]:text-inherit [&_input]:outline-none',
            ],
        },

        hasError: {
            true: 'border-coremedia-red-500 [&_i]:text-coremedia-red-500 bg-red-100',
        },
    },

    compoundVariants: [
        {
            styleGuide: 'coremedia',
            variant: 'primary',
            className: [
                'enabled:[&_i]:text-coremedia-white',
                'enabled:hover:bg-coremedia-blue-600 enabled:hover:[&_i]:text-coremedia-white',
                'enabled:data-[state=open]:bg-coremedia-blue-600 enabled:data-[state=open]:[&_i]:text-coremedia-white',
                'disabled:bg-coremedia-grey-200',
            ],
        },
        {
            styleGuide: 'coremedia',
            variant: 'secondary',
            className: [
                'enabled:[&_i]:text-coremedia-blue-500',
                'enabled:data-[state=open]:[&_i]:text-coremedia-blue-600',
            ],
        },
        {
            styleGuide: 'coremedia',
            variant: 'clean',
            className: [
                'enabled:[&_i]:text-coremedia-blue-500',
                'enabled:data-[state=open]:[&_i]:text-coremedia-blue-500',
            ],
        },
        {
            styleGuide: 'coremedia',
            variant: 'boxed',
            className: [
                '[&_i]:!text-lg',
                'enabled:data-[state=open]:hover:border-coremedia-blue-600 enabled:data-[state=open]:[&_i]:text-black',
            ],
        },
        {
            styleGuide: 'new',
            variant: 'secondary',
            className: 'text-sm font-normal',
        },
    ],

    defaultVariants: {
        variant: 'secondary',
        styleGuide: 'coremedia',
        hasError: false,
        isPopoverWithInput: false,
    },
});

type CustomButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & VariantProps<typeof pickerButtonVariants>;

export const CustomTriggerButton = forwardRef<HTMLButtonElement, CustomButtonProps>(
    ({ styleGuide, variant, size, isPopoverWithInput, hasError, className, ...rest }, forwardedRef) => (
        <button
            type="button"
            ref={forwardedRef}
            className={pickerButtonVariants({
                styleGuide,
                variant,
                size,
                isPopoverWithInput,
                hasError,
                className,
            })}
            {...rest}
        />
    ),
);
