import { MouseEvent, useCallback, useMemo, useRef, useState } from 'react';
import { format } from 'date-fns';
import { ActiveModifiers, DateRange, DayPickerRangeProps } from 'react-day-picker';
import { deepClone } from '@sidetalk/helpers';
import { Popover } from '@components/Popover';
import {
    buildIsDayDisabled,
    getDateFormatsOnOldStyle,
    getDateLocale,
    getDatePlaceholder,
    getInitialRange,
    getPlaceholderTitle,
    onSelectedRangeChangeTime,
    preserveDayTime,
    sortRangeDates,
} from '@components/DatePicker/helpers';
import { DateFormat, DatePickerRangeProps } from '@components/DatePicker';
import { Icon } from '@components/Icon';
import { DayType } from '@components/DatePicker/types';
import { CustomTriggerButton } from '@components/DatePicker/styles';
import { DatePickerFooter } from '../Footer';
import { DatePickerRoot } from '../Root';
import { DateTimePicker } from '../DateTimePicker';
import { rangePopoverButtonContainerVariants } from './styles';

export function DatePickerRange({
    isPopover = false,
    selected = getInitialRange(),
    onSelect,
    locale,
    min,
    options,
    container,
    styleGuide,
    variant = 'secondary',
    hasButtonFooter = false,
    timePicker = false,
    timePickerSeconds = false,
    numberOfMonths,
    maxDate,
    minDate,
    cancelLabel,
    acceptLabel,
    disabledPopover = false,
    isInvalidDate,
    showLocalizedDate = false,
    ...rest
}: DatePickerRangeProps) {
    const newMin = min === 1 ? 2 : min;

    const [previousSelectedRange, setPreviousSelectedRange] = useState(() => deepClone(selected));
    const [selectedRange, setSelectedRange] = useState(selected ?? getInitialRange());
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const [isFromButtonClicked, setIsFromButtonClicked] = useState(false);
    const [isToButtonClicked, setIsToButtonClicked] = useState(false);
    const [persistedValue, setPersistedValue] = useState<
        | [day: DayPickerRangeProps['selected'], selectedDay: Date, activeModifiers: ActiveModifiers, e: MouseEvent]
        | null
    >([
        {
            from: selected?.from,
            to: selected?.to,
        } as DayPickerRangeProps['selected'],
        selected!.to!,
        {} as ActiveModifiers,
        {} as MouseEvent,
    ]);

    const isDayDisabled = useMemo(() => buildIsDayDisabled(minDate, maxDate), [minDate, maxDate]);
    const hasTimePicker = timePicker;
    const shouldShowFooter = hasButtonFooter || hasTimePicker;

    // This is used to know when the user has clicked outside or
    // used the 'ESC' key to close the popover
    const isButtonClicked = useRef(false);

    const dateLocale = options?.customDateFnsLocale ?? getDateLocale(locale);

    const handleFromButtonClick = () => {
        isButtonClicked.current = true;

        if (!isPopoverOpen) {
            setIsPopoverOpen(true);
            setIsFromButtonClicked(true);

            return;
        }

        if (isPopoverOpen && isFromButtonClicked) {
            setIsPopoverOpen(false);
            setIsFromButtonClicked(false);

            return;
        }

        if (isPopoverOpen && isToButtonClicked) {
            setIsFromButtonClicked(true);
            setIsToButtonClicked(false);
        }
    };

    const handleToButtonClick = () => {
        isButtonClicked.current = true;

        if (!isPopoverOpen) {
            setIsPopoverOpen(true);

            if (selectedRange) {
                if (!selectedRange.from) {
                    setIsFromButtonClicked(true);
                } else {
                    setIsToButtonClicked(true);
                }
            } else {
                setIsFromButtonClicked(true);
            }

            return;
        }

        if (isPopoverOpen && isToButtonClicked) {
            setIsPopoverOpen(false);
            setIsToButtonClicked(false);

            return;
        }

        if (isPopoverOpen && isFromButtonClicked) {
            setIsFromButtonClicked(false);
            setIsToButtonClicked(true);
        }
    };

    const handleOnOpenChange = (open: boolean) => {
        if (!open && !isButtonClicked.current) {
            setIsPopoverOpen(false);
            setIsToButtonClicked(false);
            setIsFromButtonClicked(false);
        }

        isButtonClicked.current = false;
    };

    const onChangeTime = useCallback(
        (time: Date, day: DayType) => {
            setSelectedRange((oldState) => onSelectedRangeChangeTime(oldState, time, day, !!timePickerSeconds));
        },
        [timePickerSeconds],
    );

    const handleSelectedRangeSingleButton = (
        __range: DateRange | undefined,
        selectedDate: Date,
        activeModifiers: ActiveModifiers,
        e: MouseEvent<Element, globalThis.MouseEvent>,
    ) => {
        setSelectedRange(() => {
            const newSelectRange = {
                from:
                    ((selectedRange?.to && selectedRange.from) ?? !selectedRange?.from)
                        ? selectedDate
                        : selectedRange?.from,
                to: !selectedRange?.to && selectedRange?.from ? selectedDate : undefined,
            };

            const sortedSelectRange = sortRangeDates(newSelectRange)!;
            sortedSelectRange.from = preserveDayTime(sortedSelectRange?.from, selectedRange?.from);
            sortedSelectRange.to = preserveDayTime(sortedSelectRange?.to, selectedRange?.to, 'to');

            if (onSelect && sortedSelectRange?.from && sortedSelectRange?.to && !hasButtonFooter && !hasTimePicker) {
                setPreviousSelectedRange(sortedSelectRange);
                onSelect(sortedSelectRange, selectedDate, activeModifiers, e);
                setIsPopoverOpen(false);
            }

            if (hasButtonFooter || hasTimePicker) {
                setPersistedValue([
                    sortedSelectRange as DayPickerRangeProps['selected'],
                    selectedDate,
                    activeModifiers,
                    e,
                ]);
            }

            return sortedSelectRange;
        });
    };

    const handleSelectedRange = (
        __range: DateRange | undefined,
        selectedDate: Date,
        activeModifiers: ActiveModifiers,
        e: MouseEvent<Element, globalThis.MouseEvent>,
    ) => {
        if (isFromButtonClicked) {
            setSelectedRange((oldState) => {
                const newSelectRange = {
                    from: selectedDate,
                    to: oldState?.to,
                };

                if (onSelect && !hasButtonFooter) {
                    onSelect(newSelectRange, selectedDate, activeModifiers, e);
                }

                if (hasButtonFooter) {
                    setPersistedValue([newSelectRange, selectedDate, activeModifiers, e]);
                }

                return newSelectRange;
            });

            setIsFromButtonClicked(false);
            setIsToButtonClicked(true);

            return;
        }

        if (isToButtonClicked) {
            setSelectedRange((oldState) => {
                const newSelectRange = {
                    from: oldState?.from,
                    to: selectedDate,
                };

                if (onSelect && !hasButtonFooter) {
                    onSelect(newSelectRange, selectedDate, activeModifiers, e);
                }

                if (hasButtonFooter) {
                    setPersistedValue([newSelectRange, selectedDate, activeModifiers, e]);
                }

                return newSelectRange;
            });

            setIsToButtonClicked(false);

            if (!hasButtonFooter) {
                setIsPopoverOpen(false);
            }
        }
    };

    const formatSingleButtonDate = () => {
        if (!previousSelectedRange?.from || !previousSelectedRange?.to) {
            return `${getDatePlaceholder(options?.customPlaceholder, locale)} - ${getDatePlaceholder(
                options?.customPlaceholder,
                locale,
            )}`;
        }

        const { formatDate, formatDateAndTime, formatDateAndTimeWithSeconds } =
            getDateFormatsOnOldStyle(showLocalizedDate);

        if (
            previousSelectedRange?.from?.getSeconds() === 0 &&
            previousSelectedRange?.from?.getMinutes() === 0 &&
            previousSelectedRange?.from?.getHours() === 0 &&
            previousSelectedRange?.to?.getSeconds() === 59 &&
            previousSelectedRange?.to?.getMinutes() === 59 &&
            previousSelectedRange?.to?.getHours() === 23
        ) {
            return `${format(previousSelectedRange?.from || 0, formatDate, {
                locale: dateLocale,
            })} - ${format(previousSelectedRange?.to || 0, formatDate, {
                locale: dateLocale,
            })}`;
        }

        if (timePickerSeconds) {
            return `${format(previousSelectedRange?.from || 0, formatDateAndTimeWithSeconds, {
                locale: dateLocale,
            })} - ${format(previousSelectedRange?.to || 0, formatDateAndTimeWithSeconds, { locale: dateLocale })}`;
        }

        if (timePicker) {
            return `${format(previousSelectedRange?.from || 0, formatDateAndTime, {
                locale: dateLocale,
            })} - ${format(previousSelectedRange?.to || 0, formatDateAndTime, { locale: dateLocale })}`;
        }

        return `${format(previousSelectedRange?.from || 0, formatDate, {
            locale: dateLocale,
        })} - ${format(previousSelectedRange?.to || 0, formatDate, { locale: dateLocale })}`;
    };

    if (isPopover) {
        const popoverTitle = selected ? getPlaceholderTitle(options?.customPlaceholderTitle, locale) : undefined;
        const shouldShowIcon = styleGuide === 'new' || (styleGuide === 'coremedia' && variant === 'boxed');
        const iconType = 'byside';
        const iconName = styleGuide === 'new' ? 'schedule-calls' : 'calendar';
        const canSaveDate = persistedValue !== null && !!selectedRange?.from && !!selectedRange?.to;

        if (styleGuide === 'coremedia') {
            return (
                <Popover.Root open={isPopoverOpen} onOpenChange={disabledPopover ? undefined : handleOnOpenChange}>
                    <Popover.Trigger asChild>
                        <CustomTriggerButton
                            disabled={disabledPopover}
                            styleGuide={styleGuide}
                            variant={variant}
                            onClick={handleFromButtonClick}
                            title={popoverTitle}
                        >
                            {previousSelectedRange?.from
                                ? formatSingleButtonDate()
                                : `${getDatePlaceholder(options?.customPlaceholder, locale)} - ${getDatePlaceholder(
                                      options?.customPlaceholder,
                                      locale,
                                  )}`}
                            {shouldShowIcon && <Icon type={iconType} iconName={iconName} />}
                            {!shouldShowIcon && <Icon type="byside" iconName="arrow-small" />}
                        </CustomTriggerButton>
                    </Popover.Trigger>
                    <Popover.Content container={container} styleGuide={styleGuide}>
                        <DatePickerRoot
                            styleGuide={styleGuide}
                            mode="range"
                            selected={selectedRange}
                            onSelect={handleSelectedRangeSingleButton}
                            locale={dateLocale}
                            defaultMonth={selectedRange?.from}
                            min={newMin}
                            numberOfMonths={numberOfMonths}
                            disabled={isInvalidDate ? (day) => isInvalidDate(day) || isDayDisabled(day) : isDayDisabled}
                            {...rest}
                        />
                        <DateTimePicker
                            showTimePicker={hasTimePicker}
                            timeStart={selectedRange?.from ?? null}
                            timeEnd={selectedRange?.to}
                            onChangeTime={onChangeTime}
                            hasSeconds={!!timePickerSeconds}
                            isMultiple={numberOfMonths ? numberOfMonths > 1 : false}
                            styleGuide="coremedia"
                        />
                        <DatePickerFooter
                            showFooter={shouldShowFooter}
                            canSave={!!persistedValue}
                            cancelLabel={cancelLabel}
                            acceptLabel={acceptLabel}
                            cancelFunction={() => {
                                setSelectedRange(deepClone(previousSelectedRange));
                                setIsPopoverOpen(false);
                            }}
                            acceptFunction={() => {
                                if (canSaveDate) {
                                    onSelect?.(...persistedValue);
                                    setPreviousSelectedRange(deepClone(selectedRange));
                                    setIsPopoverOpen(false);
                                }
                            }}
                        />
                        <Popover.Arrow />
                    </Popover.Content>
                </Popover.Root>
            );
        }

        return (
            <Popover.Root open={isPopoverOpen} onOpenChange={handleOnOpenChange}>
                <Popover.Trigger asChild>
                    <div className={rangePopoverButtonContainerVariants({ styleGuide })}>
                        <CustomTriggerButton
                            disabled={disabledPopover}
                            styleGuide={styleGuide}
                            variant={variant}
                            data-state={isFromButtonClicked ? 'open' : 'closed'}
                            onClick={handleFromButtonClick}
                            title={popoverTitle}
                        >
                            {selectedRange?.from
                                ? format(selectedRange.from, DateFormat.longLocalizedDate, { locale: dateLocale })
                                : getDatePlaceholder(options?.customPlaceholder, locale)}
                            {shouldShowIcon && <Icon type={iconType} iconName={iconName} />}
                            {!shouldShowIcon && <Icon type="byside" iconName="arrow-small" />}
                        </CustomTriggerButton>
                        <CustomTriggerButton
                            disabled={disabledPopover}
                            styleGuide={styleGuide}
                            variant={variant}
                            data-state={isToButtonClicked ? 'open' : 'closed'}
                            onClick={handleToButtonClick}
                            title={popoverTitle}
                        >
                            {selectedRange?.to
                                ? format(selectedRange.to, DateFormat.longLocalizedDate, { locale: dateLocale })
                                : getDatePlaceholder(options?.customPlaceholder, locale)}
                            {shouldShowIcon && <Icon type={iconType} iconName={iconName} />}
                            {!shouldShowIcon && <Icon type="byside" iconName="arrow-small" />}
                        </CustomTriggerButton>
                    </div>
                </Popover.Trigger>
                <Popover.Content container={container} styleGuide={styleGuide}>
                    <DatePickerRoot
                        styleGuide={styleGuide}
                        mode="range"
                        selected={selectedRange}
                        onSelect={handleSelectedRange}
                        locale={dateLocale}
                        min={newMin}
                        numberOfMonths={numberOfMonths}
                        disabled={isInvalidDate ? (day) => isInvalidDate(day) || isDayDisabled(day) : isDayDisabled}
                        {...rest}
                    />
                    <DatePickerFooter
                        showFooter={shouldShowFooter}
                        cancelFunction={() => {
                            setIsPopoverOpen(false);
                        }}
                        acceptFunction={() => {
                            if (canSaveDate) {
                                onSelect?.(...persistedValue);
                            }
                        }}
                        canSave={canSaveDate}
                        cancelLabel={cancelLabel}
                        acceptLabel={acceptLabel}
                    />
                </Popover.Content>
            </Popover.Root>
        );
    }

    return (
        <DatePickerRoot
            styleGuide={styleGuide}
            mode="range"
            selected={selected}
            locale={dateLocale}
            min={newMin}
            numberOfMonths={numberOfMonths}
            disabled={isInvalidDate ? (day) => isInvalidDate(day) || isDayDisabled(day) : isDayDisabled}
            {...rest}
        />
    );
}
