import { tv } from '@lib/tailwind-variants';

const popoverSlots = tv({
    slots: {
        buttonsContainer: 'group/filter-popover relative',
        triggerButton: [
            'rounded-xs leading-base relative flex h-9 min-w-16 items-center gap-1 pl-1 pr-4',
            'text-gray-850 border border-gray-100 bg-gray-50 text-xs outline-none',
            'enabled:focus-within:bg-white enabled:data-[state=open]:bg-white',
            'group-hover/filter-popover:enabled:bg-white',
            'placeholder:text-gray-850 enabled:cursor-pointer disabled:cursor-not-allowed',
            'enabled:hover:border-red-500 enabled:focus:border-red-500',
        ],
        closeButtonContainer: [
            'absolute bottom-0 right-px top-1/2 -translate-y-1/2',
            'h-[calc(100%_-_2px)] w-2/5 bg-gradient-to-l from-white via-white transition-colors',
            'rounded-xs pointer-events-none cursor-pointer opacity-0',
            'group-data-[disabled=true]/filter-popover:hidden',
            'group-focus-within/filter-popover:opacity-100 group-hover/filter-popover:opacity-100',
        ],
        closeButton: [
            'absolute right-[5%] top-1/2 flex items-center justify-center p-1',
            'rounded-xs -translate-y-1/2 outline-1 outline-red-500',
            'group-data-[disabled=false]/filter-popover:pointer-events-auto',
            'enabled:cursor-pointer enabled:hover:outline enabled:focus:outline',
        ],
        contentButtonsContainer: 'mt-4 flex justify-between gap-4',
    },
});

export const { triggerButton, buttonsContainer, closeButtonContainer, closeButton, contentButtonsContainer } =
    popoverSlots();
