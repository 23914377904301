import { useSortable, type AnimateLayoutChanges } from '@dnd-kit/sortable';
import {
    Children,
    createContext,
    isValidElement,
    useContext,
    useMemo,
    type CSSProperties,
    type ReactElement,
} from 'react';
import { CSS } from '@dnd-kit/utilities';
import { SortableItem } from '../components/SortableItem';
import type { DraggableAttributes, UniqueIdentifier } from '@dnd-kit/core';
import type { DraggableListResources, FlattenedTreeItem } from '../types';

type DraggableItemContextProps = {
    style: CSSProperties;
    isOverlay: boolean;
    childCount: number;
    element: FlattenedTreeItem;
    disableSelection: boolean;
    disableInteraction: boolean;
    handleProps: DraggableAttributes & {
        ref: (node: HTMLElement | null) => void;
    };
    dndSortableHook: ReturnType<typeof useSortable>;
};

const DraggableItemContext = createContext<DraggableItemContextProps | null>(null);

export type DraggableItemContextProviderProps = {
    id: UniqueIdentifier;
    resources: DraggableListResources;
    isOverlay?: boolean;
    childCount?: number;
    element: FlattenedTreeItem;
    children?: ReactElement;
};

const animateLayoutChanges: AnimateLayoutChanges = ({ isSorting, wasDragging }) =>
    isSorting || wasDragging ? false : true;

export function DraggableItemContextProvider({
    id,
    resources,
    isOverlay = false,
    childCount = 0,
    element,
    children,
}: DraggableItemContextProviderProps) {
    if (children && Children.count(children) > 1) {
        throw new Error('DraggableList.Iterator accepts only a single child element');
    }

    const dndSortableHook = useSortable({
        id,
        animateLayoutChanges,
        attributes: {
            roleDescription: `${resources.sortable} ${element.title}`,
        },
    });

    const { isSorting, transform, transition, setActivatorNodeRef, attributes, listeners } = dndSortableHook;

    const childProps = isValidElement(children) ? children.props : {};
    const DraggableListItem = (
        isValidElement(children) ? children.type : (children ?? SortableItem)
    ) as typeof SortableItem;

    const style: CSSProperties = useMemo(
        () => ({
            transform: CSS.Translate.toString(transform),
            transition,
        }),
        [transform, transition],
    );

    const draggableItemContextValue = useMemo(() => {
        const iOS = /iPad|iPhone|iPod/.test(navigator?.userAgent);

        return {
            style,
            isOverlay,
            childCount,
            element,
            disableSelection: iOS,
            disableInteraction: isSorting,
            handleProps: {
                ref: setActivatorNodeRef,
                ...attributes,
                ...listeners,
            },
            dndSortableHook,
        };
    }, [style, isOverlay, childCount, element, isSorting, setActivatorNodeRef, attributes, listeners, dndSortableHook]);

    return (
        <DraggableItemContext.Provider value={draggableItemContextValue}>
            <DraggableListItem {...(childProps as object)} />
        </DraggableItemContext.Provider>
    );
}

export function useDraggableItem(componentName: string) {
    const draggableItemContext = useContext(DraggableItemContext);

    if (!draggableItemContext) {
        throw new Error(`DraggableItem.${componentName} has to be inside the DraggableItem.Root component`);
    }

    return draggableItemContext;
}
