import { Group } from '@radix-ui/react-dropdown-menu';
import { DropdownMenuItems } from '@components/Droplist/types';
import { BysideClassNamesIcons, Icon } from '@components/Icon';
import { Tooltip } from '@components/Tooltip';
import { CustomItem, CustomLabel, CustomSeparator } from '@components/Droplist/styles';

type MenuProps = {
    chosen?: DropdownMenuItems;
    item?: DropdownMenuItems;
    onSelect?: (item: DropdownMenuItems) => void;
};

export function Menu({ chosen, onSelect, item = {} }: MenuProps) {
    const { label, disabled, icon, iconColor, tooltip, items, component, separator } = item;

    if (items) {
        return (
            <>
                <Group>
                    {label && <CustomLabel>{label}</CustomLabel>}
                    {items.map((groupItem) => (
                        <Menu
                            key={groupItem.id ?? groupItem.value}
                            chosen={chosen}
                            onSelect={onSelect}
                            item={groupItem}
                        />
                    ))}
                </Group>
                {separator && <CustomSeparator />}
            </>
        );
    }

    if (component) {
        return component;
    }

    const isItemSelected = chosen?.label === label;

    return (
        <Tooltip.Basic content={tooltip?.label} {...tooltip?.options}>
            <CustomItem
                disabled={disabled}
                onClick={(e) => e.stopPropagation()}
                onSelect={() => onSelect?.(item)}
                data-active={isItemSelected}
            >
                {icon && (
                    <Icon
                        type="byside"
                        iconName={icon.replace('byside-icon-', '') as BysideClassNamesIcons}
                        color={iconColor}
                    />
                )}
                {label}
            </CustomItem>
        </Tooltip.Basic>
    );
}
