import { forwardRef, type HTMLAttributes, type ReactElement, type Ref } from 'react';
import {
    DndContext,
    closestCenter,
    DragOverlay,
    MeasuringStrategy,
    defaultDropAnimation,
    type Modifier,
    type DropAnimation,
} from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import { verticalListSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import { cn } from '@helpers/index';
import { Portal } from '@components/Portal';
import { getChildCount, MAX_DEFAULT_DEPTH } from './helpers';
import {
    DraggableListContextProvider,
    useDraggableList,
    type DraggableListContextProviderProps,
} from './contexts/draggableList';
import { useDraggableItem } from './contexts/draggableItem';
import { DraggableItemContextProvider } from './contexts/draggableItem';
import { SortableItem } from './components/SortableItem';
import {
    CollapseButton,
    DraggableButton,
    ChildCountBadge,
    ActionButton,
    EmptyElementsMessageContainer,
} from './styles';
import type { TreeItem } from './types';

export type DraggableListElement = TreeItem;

export { useDraggableList, useDraggableItem };

const measuring = {
    droppable: {
        strategy: MeasuringStrategy.Always,
    },
};

const dropAnimationConfig: DropAnimation = {
    keyframes({ transform }) {
        return [
            { opacity: 1, transform: CSS.Transform.toString(transform.initial) },
            {
                opacity: 0,
                transform: CSS.Transform.toString({
                    ...transform.final,
                    x: transform.final.x + 5,
                    y: transform.final.y + 5,
                }),
            },
        ];
    },
    easing: 'ease-out',
    sideEffects({ active }) {
        active.node.animate([{ opacity: 0 }, { opacity: 1 }], {
            duration: defaultDropAnimation.duration,
            easing: defaultDropAnimation.easing,
        });
    },
};

const adjustTranslate: Modifier = ({ transform }) => {
    return {
        ...transform,
        y: transform.y - 25,
    };
};

type DraggableListContextProps = {
    children?: ReactElement;
};

function DraggableListContext({ children }: DraggableListContextProps) {
    const {
        items,
        flattenedItems,
        activeId,
        activeItem,
        hasIndicator,
        disabled,
        announcements,
        sensors,
        sortedIds,
        resources,
        handleDragStart,
        handleDragMove,
        handleDragOver,
        handleDragEnd,
        handleDragCancel,
    } = useDraggableList('RootWithDndContext');

    return (
        <DndContext
            accessibility={{
                announcements,
                screenReaderInstructions: {
                    draggable: resources.instructions,
                },
            }}
            sensors={sensors}
            collisionDetection={closestCenter}
            measuring={measuring}
            onDragStart={handleDragStart}
            onDragMove={handleDragMove}
            onDragOver={handleDragOver}
            onDragEnd={handleDragEnd}
            onDragCancel={handleDragCancel}
        >
            <SortableContext items={sortedIds} disabled={disabled} strategy={verticalListSortingStrategy}>
                {flattenedItems.map(({ id, ...rest }) => (
                    <DraggableItemContextProvider
                        key={id}
                        id={id}
                        element={{
                            id,
                            ...rest,
                        }}
                        resources={resources}
                    >
                        {children}
                    </DraggableItemContextProvider>
                ))}
            </SortableContext>
            <Portal.Root>
                <DragOverlay
                    dropAnimation={dropAnimationConfig}
                    modifiers={hasIndicator ? [adjustTranslate] : undefined}
                >
                    {activeId && !!activeItem && (
                        <DraggableItemContextProvider
                            isOverlay
                            id={activeId}
                            childCount={getChildCount(items, activeId)}
                            element={activeItem}
                            resources={resources}
                        >
                            {children}
                        </DraggableItemContextProvider>
                    )}
                </DragOverlay>
            </Portal.Root>
        </DndContext>
    );
}

DraggableListContext.displayName = 'DraggableList.Iterator';

type RootGenericProps<T extends TreeItem> = HTMLAttributes<HTMLDivElement> &
    DraggableListContextProviderProps & {
        elements: T[];
        onItemEvents?: (element: T, action: string) => void;
        onUpdateElements?: (elements: T[]) => void;
        onResetLabel?: (element: T) => void;
    };

function Root<T extends TreeItem>(
    {
        elements,
        allowCollapse = false,
        disabled = false,
        hasIndicator = true,
        indentationWidth = 36,
        maxDepth = MAX_DEFAULT_DEPTH,
        orderedElementId,
        orderedElementOrder,
        resources,
        onItemEvents,
        onUpdateElements,
        onResetLabel,
        onDragStart,
        onDragMove,
        onDragOver,
        onDragEnd,
        onDragCancel,
        className,
        ...rest
    }: RootGenericProps<T>,
    forwardedRef: Ref<HTMLDivElement>,
) {
    return (
        <DraggableListContextProvider
            elements={elements}
            allowCollapse={allowCollapse}
            disabled={disabled}
            hasIndicator={hasIndicator}
            orderedElementId={orderedElementId}
            orderedElementOrder={orderedElementOrder}
            indentationWidth={indentationWidth}
            maxDepth={maxDepth}
            resources={resources}
            onItemEvents={onItemEvents}
            onUpdateElements={onUpdateElements}
            onResetLabel={onResetLabel}
            onDragStart={onDragStart}
            onDragMove={onDragMove}
            onDragOver={onDragOver}
            onDragEnd={onDragEnd}
            onDragCancel={onDragCancel}
        >
            <div
                ref={forwardedRef}
                className={cn('bg-coremedia-grey-200 flex w-full grow flex-col', className)}
                {...rest}
            />
        </DraggableListContextProvider>
    );
}

Root.displayName = 'DraggableList.Root';

const ForwardRefGenericRoot = forwardRef(Root) as <T extends TreeItem>(
    props: RootGenericProps<T> & { ref?: Ref<HTMLDivElement> },
) => ReturnType<typeof Root>;

export const DraggableList = {
    Root: ForwardRefGenericRoot,
    Iterator: DraggableListContext,
    SortableItem,
    DraggableButton,
    CollapseButton,
    ChildCountBadge,
    ActionButton,
    EmptyElementsMessage: EmptyElementsMessageContainer,
};
