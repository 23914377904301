import { ElementRef, forwardRef, ReactNode } from 'react';
import { Text } from '@components/Text';
import { CustomItem, CustomRoot } from './styles';

export type ToggleGroupRootProps = typeof CustomRoot;

type BasicList = {
    label: ReactNode;
    value: string;
    checked?: boolean;
    disabled?: boolean;
};

type BasicProps = {
    list?: BasicList[];
    emptyListText?: string;
    onListChange?: (list: BasicList[]) => void;
    className?: string;
    disabled?: boolean;
};

export const Basic = forwardRef<ElementRef<typeof CustomRoot>, BasicProps>(
    ({ list = [], emptyListText = 'No results found', onListChange, disabled, className }, forwardedRef) => {
        if (list.length === 0) {
            return (
                <div ref={forwardedRef} className={className}>
                    <Text className="pl-3 pt-2.5">{emptyListText}</Text>
                </div>
            );
        }

        const handleToggleGroup = (checkedItems: string[]) => {
            const newList = list.map((item) => ({
                ...item,
                checked: checkedItems.includes(item.value),
            }));

            onListChange?.(newList);
        };

        const itemsChecked = list.filter((item) => item.checked).map((item) => item.value);

        return (
            <CustomRoot
                ref={forwardedRef}
                type="multiple"
                value={itemsChecked}
                onValueChange={handleToggleGroup}
                className={className}
                disabled={disabled}
            >
                {list.map(({ value, label, disabled }) => (
                    <CustomItem key={value} value={value} disabled={disabled}>
                        {label}
                    </CustomItem>
                ))}
            </CustomRoot>
        );
    },
);

Basic.displayName = 'ToggleGroup.Basic';

export const ToggleGroup = {
    Root: CustomRoot,
    Item: CustomItem,
    Basic,
};
