import { ElementRef, forwardRef } from 'react';
import {
    Root,
    ToggleGroupItem,
    type ToggleGroupMultipleProps,
    type ToggleGroupSingleProps,
    type ToggleGroupItemProps,
} from '@radix-ui/react-toggle-group';
import { cn } from '@helpers/index';

export const CustomRoot = forwardRef<ElementRef<typeof Root>, ToggleGroupSingleProps | ToggleGroupMultipleProps>(
    ({ orientation = 'horizontal', className, ...rest }, forwardedRef) => {
        return (
            <Root
                ref={forwardedRef}
                className={cn('flex w-full flex-1 flex-wrap overflow-hidden py-1', className)}
                orientation={orientation}
                {...rest}
            />
        );
    },
);

CustomRoot.displayName = 'ToggleGroup.Root';

export const CustomItem = forwardRef<ElementRef<typeof ToggleGroupItem>, ToggleGroupItemProps>(
    ({ className, ...rest }, forwardedRef) => {
        return (
            <ToggleGroupItem
                ref={forwardedRef}
                className={cn(
                    [
                        'm-px flex h-8 min-w-8 items-center justify-center p-1 transition-colors',
                        'enabled:focus-visible:outline-coremedia-blue-600 enabled:focus-visible:outline-1 enabled:focus-visible:outline-offset-1',
                        'text-coremedia-grey-600 cursor-pointer text-center',
                        'border-coremedia-grey-600 border border-solid',
                        'data-[state=on]:text-coremedia-grey-100',
                        'data-[state=on]:border-coremedia-blue-500 data-[state=on]:bg-coremedia-blue-500',
                        'disabled:data-[state=off]:text-coremedia-grey-400 disabled:cursor-not-allowed',
                        'disabled:data-[state=on]:bg-coremedia-blue-400 disabled:data-[state=on]:border-coremedia-blue-400',
                    ],
                    className,
                )}
                {...rest}
            />
        );
    },
);

CustomItem.displayName = 'ToggleGroup.Item';
