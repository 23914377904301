import { useEffect } from 'react';
import { coremediaColors } from '@sidetalk/tokens';
import { Select } from '@components/Select';
import { useQueryBuilderState } from '../../context/QueryBuilder';
import type { OperatorSelectorProps, Operator } from 'react-querybuilder';

const customSelectStyles = {
    container: () => ({
        backgroundColor: coremediaColors.white,
        minWidth: '104px',
    }),
    valueContainer: () => ({
        display: 'flex',
        alignItems: 'center',
    }),
};

export function OperatorSelector({ field, options, value, handleOnChange }: OperatorSelectorProps) {
    const { state } = useQueryBuilderState();
    const { resources, disabled } = state;

    const operators = options.map((opt) => ({ value: (opt as Operator<string>).name, label: opt.label }));

    useEffect(() => {
        handleOnChange(operators.find((o) => o.value === value)?.value ?? operators[0]?.value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [field]);

    const defaultValue = operators.find((o) => o.value === value) ?? operators[0];

    return (
        <Select.Basic
            styleGuide="coremedia"
            className="operator-selector"
            styles={customSelectStyles}
            key={`${field}-${defaultValue.value}`}
            name="operator"
            options={operators}
            menuPortalTarget={document.body}
            isSearchable={false}
            defaultValue={defaultValue}
            onChange={(option) => {
                if (option?.value) {
                    handleOnChange(option?.value);
                }
            }}
            menuPlacement="auto"
            noOptionsMessage={() => resources?.no_options ?? 'No options'}
            isDisabled={disabled}
        />
    );
}
