import { ElementRef, forwardRef } from 'react';
import {
    Root,
    Indicator,
    RadioGroupItem,
    type RadioGroupProps,
    type RadioGroupIndicatorProps,
    type RadioGroupItemProps,
} from '@radix-ui/react-radio-group';
import { cn } from '@helpers/index';

export const CustomRoot = forwardRef<ElementRef<typeof Root>, RadioGroupProps>(
    ({ className, ...rest }, forwardedRef) => (
        <Root
            ref={forwardedRef}
            className={cn('flex flex-col aria-[orientation=horizontal]:flex-row', className)}
            {...rest}
        />
    ),
);

CustomRoot.displayName = 'RadioGroup.Root';

export const CustomIndicator = forwardRef<ElementRef<typeof Indicator>, RadioGroupIndicatorProps>(
    ({ className, ...rest }, forwardedRef) => (
        <Indicator
            ref={forwardedRef}
            className={cn('bg-coremedia-green-500 mx-auto block size-3 rounded-full', className)}
            {...rest}
        />
    ),
);

CustomIndicator.displayName = 'RadioIndicator';

export const CustomRadioGroupItem = forwardRef<ElementRef<typeof RadioGroupItem>, RadioGroupItemProps>(
    ({ className, ...rest }, forwardedRef) => (
        <RadioGroupItem
            ref={forwardedRef}
            className={cn(
                [
                    'bg-coremedia-white relative m-1.5 box-border block size-[1.125rem] p-0',
                    'border-coremedia-green-500 rounded-full border border-solid outline-none',
                    'focus-visible:outline-coremedia-blue-500 focus-visible:outline focus-visible:outline-1 focus-visible:outline-offset-2',
                    'disabled:border-coremedia-grey-100 disabled:[&_span]:bg-coremedia-grey-100',
                ],
                className,
            )}
            {...rest}
        />
    ),
);
