/* eslint-disable react-compiler/react-compiler */
import { ComponentPropsWithoutRef, ElementRef, MouseEvent as ReactMouseEvent, Ref, forwardRef, useRef } from 'react';
import { Step, StepBarContainer } from './styles';
import { StepBarContextProvider, StepBarContextProviderProps } from './context';

export type StepProps<T = string | number> = {
    id: T;
    label: string;
    isDisabled?: boolean;
    enabled?: boolean;
};

export type BasicStepBarProps<T = string | number> = Omit<
    ComponentPropsWithoutRef<typeof StepBarContainer> & StepBarContextProviderProps,
    'children'
> & {
    steps: StepProps<T>[];
    currentStep: T;
    onStepClick?: (stepId: T, index: number, e: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

function BasicStepBar<T extends string | number>(
    { steps, currentStep, onStepClick, styleGuide, ...rest }: BasicStepBarProps<T>,
    ref: Ref<ElementRef<typeof StepBarContainer>>,
) {
    const isVisited = useRef<boolean | undefined>();

    isVisited.current = true;

    return (
        <StepBarContextProvider styleGuide={styleGuide} {...rest}>
            <StepBarContainer ref={ref}>
                {steps.map(({ id, label, isDisabled = false, enabled }, i) => {
                    const isActiveStep = id === currentStep;

                    isVisited.current = isActiveStep ? undefined : isVisited.current;

                    return (
                        <Step
                            key={id}
                            disabled={isDisabled}
                            aria-current={isActiveStep ? 'step' : false}
                            data-visited={isVisited.current}
                            data-enabled={!!enabled}
                            onClick={(e) => !!enabled && !isDisabled && onStepClick?.(id, i, e)}
                        >
                            {label}
                        </Step>
                    );
                })}
            </StepBarContainer>
        </StepBarContextProvider>
    );
}

const ForwardRefGenericBasic = forwardRef(BasicStepBar) as <T extends string | number>(
    props: BasicStepBarProps<T> & { ref?: Ref<HTMLDivElement> },
) => ReturnType<typeof BasicStepBar>;

export const StepBar = {
    Root: StepBarContainer,
    Step,
    Basic: ForwardRefGenericBasic,
};
