import { ComponentPropsWithRef, HTMLAttributes, forwardRef } from 'react';
import { Slot } from '@radix-ui/react-slot';
import { VariantProps, tv } from '@lib/tailwind-variants';

export const textVariants = tv({
    base: 'font-default m-0',

    variants: {
        styleGuide: {
            new: 'text-base text-gray-700',

            coremedia: 'text-[0.9375rem]',
        },

        type: {
            'info/default': '',
            'info/info_highlight': '',
            'misc/badge': '',
            'misc/empty_state': '',
            light: '',
            medium: '',
            regular: '',
            bold: '',
            regular_link: '',
            light_link: '',
        },

        size: {
            xxs: '',
            xs: '',
            sm: '',
            md: '',
            lg: '',
            xl: '',
            '2xl': '',
            '4xl': '',
            '5xl': '',
            '6xl': '',
            '7xl': '',
            '8xl': '',
            '9xl': '',
        },

        weight: {
            light: '',
            regular: '',
            medium: '',
            semibold: '',
            bold: '',
        },
    },

    compoundVariants: [
        {
            styleGuide: 'new',
            size: 'xxs',
            className: 'text-xxs',
        },
        {
            styleGuide: 'new',
            size: 'xs',
            className: 'text-xs',
        },
        {
            styleGuide: 'new',
            size: 'sm',
            className: 'text-sm',
        },
        {
            styleGuide: 'new',
            size: 'md',
            className: 'text-md',
        },
        {
            styleGuide: 'new',
            size: 'lg',
            className: 'text-lg',
        },
        {
            styleGuide: 'new',
            size: 'xl',
            className: 'text-xl',
        },
        {
            styleGuide: 'new',
            size: '2xl',
            className: 'text-2xl',
        },
        {
            styleGuide: 'new',
            size: '4xl',
            className: 'text-4xl',
        },
        {
            styleGuide: 'new',
            size: '4xl',
            className: 'text-4xl',
        },
        {
            styleGuide: 'new',
            size: '5xl',
            className: 'text-5xl',
        },
        {
            styleGuide: 'new',
            size: '6xl',
            className: 'text-6xl',
        },
        {
            styleGuide: 'new',
            size: '7xl',
            className: 'text-7xl',
        },
        {
            styleGuide: 'new',
            size: '8xl',
            className: 'text-8xl',
        },
        {
            styleGuide: 'new',
            size: '9xl',
            className: 'text-9xl',
        },
        {
            styleGuide: 'new',
            weight: 'light',
            className: 'font-light',
        },
        {
            styleGuide: 'new',
            weight: 'regular',
            className: 'font-regular',
        },
        {
            styleGuide: 'new',
            weight: 'medium',
            className: 'font-medium',
        },
        {
            styleGuide: 'new',
            weight: 'semibold',
            className: 'font-semibold',
        },
        {
            styleGuide: 'new',
            weight: 'bold',
            className: 'font-bold',
        },
        {
            styleGuide: 'coremedia',
            type: 'info/default',
            className: 'text-xs font-light leading-[initial]',
        },
        {
            styleGuide: 'coremedia',
            type: 'info/info_highlight',
            className: 'text-xs font-medium leading-[initial]',
        },
        {
            styleGuide: 'coremedia',
            type: 'misc/badge',
            className: 'text-[0.5rem] font-medium leading-[initial]',
        },
        {
            styleGuide: 'coremedia',
            type: 'misc/empty_state',
            className: 'font-regular italic leading-[initial]',
        },
        {
            styleGuide: 'coremedia',
            type: 'light',
            className: 'font-light leading-[150%]',
        },
        {
            styleGuide: 'coremedia',
            type: 'medium',
            className: 'font-medium leading-[150%]',
        },
        {
            styleGuide: 'coremedia',
            type: 'regular',
            className: 'font-regular leading-[150%]',
        },
        {
            styleGuide: 'coremedia',
            type: 'bold',
            className: 'font-bold leading-[150%]',
        },
        {
            styleGuide: 'coremedia',
            type: 'regular_link',
            className: 'font-regular leading-[150%] underline',
        },
        {
            styleGuide: 'coremedia',
            type: 'light_link',
            className: 'font-light leading-[150%] underline',
        },
    ],

    defaultVariants: {
        styleGuide: 'coremedia',
        type: 'regular',
        size: 'md',
        weight: 'regular',
    },
});

type CustomTextProps = HTMLAttributes<HTMLParagraphElement> &
    VariantProps<typeof textVariants> & {
        asChild?: boolean;
    };

export const Text = forwardRef<HTMLHeadingElement, CustomTextProps>(
    ({ styleGuide, type, size, weight, className, asChild, ...rest }, forwardedRef) => {
        const Comp = asChild ? Slot : 'p';

        return (
            <Comp
                ref={forwardedRef}
                className={textVariants({ styleGuide, type, size, weight, className })}
                {...rest}
            />
        );
    },
);

Text.displayName = 'Text';

export type TextProps = ComponentPropsWithRef<typeof Text>;
