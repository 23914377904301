import { tv } from '@lib/tailwind-variants';
import type { ClassNames } from 'react-day-picker';
import type { DatePickerVariants } from '@components/DatePicker/types';

const navigationSlots = tv({
    slots: {
        container: 'flex h-10 items-center',
        monthName: 'mx-auto capitalize',
        button: [
            'rounded-xs p-2 leading-none',
            'enabled:focus-visible:outline-coremedia-blue-600 enabled:hover:cursor-pointer enabled:focus-visible:outline-1',
            'disabled:cursor-not-allowed disabled:text-gray-100',
        ],
    },

    variants: {
        styleGuide: {
            new: {
                container: 'px-1',
                button: 'outline-red-500 enabled:focus:outline-1',
            },

            coremedia: {
                container: 'text-coremedia-blue-500 px-3',
                monthName: 'text-coremedia-blue-500 text-sm font-semibold',
                button: 'outline-none',
            },
        },

        isDropdown: {
            true: {
                container: String.raw`[&_.sidetalk-select\_\_control]:min-w-24`,
            },
        },

        isWithButtons: {
            true: {
                container: 'justify-between',
            },

            false: {
                container: 'justify-center gap-2',
            },
        },
    },

    compoundVariants: [
        {
            isDropdown: true,
            styleGuide: 'coremedia',
            className: String.raw`p-0 [&_.sidetalk-select\_\_control]:min-w-[4.5rem]`,
        },
        {
            isDropdown: true,
            styleGuide: 'coremedia',
            isWithButtons: true,
            className: String.raw`gap-0 [&_.sidetalk-select\_\_control]:min-w-[4.5rem]`,
        },
    ],

    defaultVariants: {
        styleGuide: 'coremedia',
        isDropdown: false,
        isWithButtons: false,
    },
});

export const { container, button, monthName } = navigationSlots();

const datePickerSlots = tv({
    slots: {
        vhidden: 'sr-only',
        months: 'flex gap-4',
        table: 'm-0 border-collapse',
        headRow: 'h-8',
        headCell: 'cursor-default p-0 text-center align-middle uppercase',
        row: 'h-full',
        cell: 'relative z-0 overflow-hidden p-0 text-center',
        weeknumber: 'font-regular bg-gray-30 grid h-full items-center text-gray-400',
        day: [
            'm-0 flex items-center justify-center overflow-hidden',
            'font-regular border-2 border-transparent enabled:cursor-pointer',
            String.raw`[&.rdp-day\_range\_start.rdp-day\_range\_end]:after:content-none`,
            'disabled:cursor-not-allowed',
        ],
        dayOutside: 'rdp-day_outside',
        dayToday: '!font-bold',
        daySelected: 'rdp-day_selected',
        buttonReset: 'outline-none',
        dayRangeMiddle: '!rounded-none',
        dayRangeStart: 'after:absolute after:inset-0 after:z-[-1] after:translate-x-1/2',
        dayRangeEnd: 'after:absolute after:inset-0 after:z-[-1] after:-translate-x-1/2',
    },

    variants: {
        styleGuide: {
            new: {
                table: 'max-w-[calc(2.5rem_*_7)]',
                headRow: 'bg-gray-30',
                headCell: 'font-regular h-10 text-xs text-gray-400',
                cell: 'size-10 max-w-10',
                weeknumber: 'text-xs',
                day: [
                    'text-gray-850 size-10 max-w-10 rounded-full text-xs',
                    'enabled:hover:bg-red-100 enabled:focus-visible:border-red-500',
                    'disabled:text-gray-100',
                ],
                dayOutside: 'enabled:text-gray-100',
                daySelected: '!bg-red-100',
                dayRangeMiddle: '!bg-red-50',
                dayRangeStart: 'after:bg-red-50',
                dayRangeEnd: 'after:bg-red-50',
            },

            coremedia: {
                months: 'p-2.5',
                table: 'block',
                headRow: 'bg-coremedia-white',
                headCell: 'text-coremedia-black h-8 text-[0.8125rem] font-semibold',
                cell: 'size-8 max-w-8',
                weeknumber: 'text-sm',
                day: [
                    'rounded-xs text-coremedia-black size-8 max-h-8 max-w-8 text-sm',
                    'enabled:hover:bg-coremedia-grey-200 enabled:focus-visible:border-coremedia-blue-600',
                    'disabled:text-coremedia-grey-400 disabled:line-through',
                ],
                dayOutside: String.raw`[&.rdp-day\_outside]:enabled:text-coremedia-grey-400`,
                daySelected: String.raw`[&.rdp-day\_selected]:bg-coremedia-blue-500 [&.rdp-day\_selected]:hover:bg-coremedia-blue-500 [&.rdp-day\_selected]:text-white`,
                dayRangeMiddle: '!text-coremedia-black !bg-coremedia-blue-100',
                dayRangeStart: 'after:bg-coremedia-blue-100',
                dayRangeEnd: 'after:bg-coremedia-blue-100',
            },
        },
    },

    defaultVariants: {
        styleGuide: 'coremedia',
    },
});

export const getDatePickerStyles = (styleGuide: DatePickerVariants['styleGuide']): ClassNames => {
    const {
        vhidden,
        months,
        table,
        headRow,
        headCell,
        row,
        cell,
        weeknumber,
        day,
        dayOutside,
        dayToday,
        daySelected,
        buttonReset,
        dayRangeMiddle,
        dayRangeStart,
        dayRangeEnd,
    } = datePickerSlots({ styleGuide });

    return {
        vhidden: vhidden(),
        months: months(),
        table: table(),
        head_row: headRow(),
        head_cell: headCell(),
        row: row(),
        cell: cell(),
        weeknumber: weeknumber(),
        day: day(),
        day_outside: dayOutside(),
        day_today: dayToday(),
        day_selected: daySelected(),
        button_reset: buttonReset(),
        day_range_middle: dayRangeMiddle(),
        day_range_start: dayRangeStart(),
        day_range_end: dayRangeEnd(),
    };
};
