import { ElementRef, forwardRef, HTMLAttributes } from 'react';
import {
    Root,
    TabsTrigger,
    TabsList,
    TabsContent,
    type TabsProps,
    type TabsTriggerProps,
    type TabsListProps,
    type TabsContentProps,
} from '@radix-ui/react-tabs';
import { cn } from '@helpers/index';

type IconCircleProps = HTMLAttributes<HTMLDivElement> & {
    isChecked?: boolean;
};

export const IconCircle = forwardRef<HTMLDivElement, IconCircleProps>(
    ({ isChecked = false, className, ...rest }, forwardedRef) => (
        <div
            ref={forwardedRef}
            data-is-checked={isChecked}
            className={cn(
                [
                    'relative mr-4 flex size-10 items-center justify-center rounded-full',
                    'text-coremedia-white bg-red-500 text-lg leading-6',
                    'after:pointer-events-none after:absolute after:bg-gray-100',
                    'group-data-[orientation=horizontal]/steps:after:inset-x-0',
                    'group-data-[orientation=horizontal]/steps:after:top-5 group-data-[orientation=horizontal]/steps:after:w-5',
                    'group-data-[orientation=horizontal]/steps:after:h-px group-data-[orientation=horizontal]/steps:after:translate-x-[calc(-100%_-_0.25rem)]',
                    'group-data-[orientation=vertical]/steps:after:inset-y-0',
                    'group-data-[orientation=vertical]/steps:after:left-5 group-data-[orientation=vertical]/steps:after:w-px',
                    'group-data-[orientation=vertical]/steps:after:h-6 group-data-[orientation=vertical]/steps:after:-translate-y-full',
                    'data-[is-checked=true]:bg-green-500 data-[is-checked=true]:text-2xl',
                ],
                className,
            )}
            {...rest}
        />
    ),
);

export const CustomList = forwardRef<ElementRef<typeof TabsList>, TabsListProps>(
    ({ className, ...rest }, forwardedRef) => (
        <TabsList
            ref={forwardedRef}
            className={cn(
                [
                    'relative flex w-full flex-1 gap-6',
                    'data-[orientation=vertical]:flex-col',
                    'data-[orientation=horizontal]:justify-center data-[orientation=horizontal]:space-x-1',
                    '[&_:first-child_[data-is-checked]]:after:content-none',
                ],
                className,
            )}
            {...rest}
        />
    ),
);

CustomList.displayName = 'Steps.List';

export const CustomTrigger = forwardRef<ElementRef<typeof TabsTrigger>, TabsTriggerProps>(
    ({ className, ...rest }, forwardedRef) => (
        <TabsTrigger
            ref={forwardedRef}
            className={cn(
                [
                    'relative z-[2] flex cursor-pointer items-center rounded',
                    'bg-white outline-none transition-colors',
                    'text-gray-250 text-xs font-semibold leading-6',
                    'enabled:hover:text-gray-850 enabled:focus-visible:text-gray-850',
                    'ring-coremedia-blue-500 ring-offset-1 enabled:focus-visible:ring',
                    'enabled:data-[state=active]:text-black',
                    'data-[orientation=vertical]:w-full',
                    'disabled:cursor-not-allowed',
                ],
                className,
            )}
            {...rest}
        />
    ),
);

CustomTrigger.displayName = 'Steps.Trigger';

export const CustomContent = forwardRef<ElementRef<typeof TabsContent>, TabsContentProps>(
    ({ className, ...rest }, forwardedRef) => (
        <TabsContent ref={forwardedRef} className={cn('h-auto flex-1 outline-none', className)} {...rest} />
    ),
);

CustomContent.displayName = 'Steps.Content';

type SeparatorProps = HTMLAttributes<HTMLDivElement>;

export const Separator = forwardRef<HTMLDivElement, SeparatorProps>(({ className, ...rest }, forwardedRef) => (
    <div
        ref={forwardedRef}
        className={cn(
            [
                'hidden bg-gray-100 group-data-[has-separator=true]/steps:!block',
                'group-data-[orientation=vertical]/steps:mx-9 group-data-[orientation=vertical]/steps:w-px',
                'group-data-[orientation=horizontal]/steps:my-9 group-data-[orientation=horizontal]/steps:h-px',
            ],
            className,
        )}
        {...rest}
    />
));

type CustomRootProps = TabsProps & {
    hasSeparator?: boolean;
};

export const CustomRoot = forwardRef<ElementRef<typeof Root>, CustomRootProps>(
    ({ hasSeparator = false, className, ...rest }, forwardedRef) => (
        <Root
            ref={forwardedRef}
            data-has-separator={hasSeparator}
            className={cn(
                [
                    'group/steps grid',
                    'data-[orientation=vertical]:data-[has-separator=true]:grid-cols-[minmax(0,1fr)_auto_minmax(0,1fr)]',
                    'data-[orientation=vertical]:grid-cols-2',
                ],
                className,
            )}
            {...rest}
        />
    ),
);

CustomRoot.displayName = 'Steps.Root';
