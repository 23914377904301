import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';
import { Icon } from '@components/Icon';
import { CustomRoot, CustomList, CustomTrigger, CustomContent, IconCircle, Separator } from './styles';

type CustomRootProps = ComponentPropsWithoutRef<typeof CustomRoot>;

type RootProps = Omit<CustomRootProps, 'defaultValue' | 'value' | 'onValueChange'> & {
    defaultStep: CustomRootProps['defaultValue'];
    step: CustomRootProps['value'];
    onStepChange: CustomRootProps['onValueChange'];
};

const Root = forwardRef<ElementRef<typeof CustomRoot>, RootProps>(
    ({ defaultStep, step, onStepChange, ...rest }, forwardedRef) => (
        <CustomRoot ref={forwardedRef} {...rest} defaultValue={defaultStep} value={step} onValueChange={onStepChange} />
    ),
);

export type StepsRootProps = typeof Root;

Root.displayName = 'Steps.Root';

const List = forwardRef<ElementRef<typeof CustomList>, ComponentPropsWithoutRef<typeof CustomList>>(
    (listProps, forwardedRef) => (
        <>
            <CustomList ref={forwardedRef} {...listProps} />
            <Separator />
        </>
    ),
);

List.displayName = 'Steps.List';

type TriggerProps = ComponentPropsWithoutRef<typeof CustomTrigger> & {
    stepOrder: number;
    isStepCompleted?: boolean;
};

const Trigger = forwardRef<ElementRef<typeof CustomTrigger>, TriggerProps>(
    ({ isStepCompleted = false, stepOrder, children, ...rest }, forwardedRef) => (
        <CustomTrigger ref={forwardedRef} {...rest}>
            <IconCircle isChecked={isStepCompleted}>
                {isStepCompleted ? <Icon type="bytalk" iconName="check-on" /> : stepOrder}
            </IconCircle>
            {children}
        </CustomTrigger>
    ),
);

Trigger.displayName = 'Steps.Trigger';

export const Steps = {
    Root,
    List,
    Trigger,
    Content: CustomContent,
};
