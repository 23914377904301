import { ClassNames } from 'react-day-picker';
import { tv } from '@lib/tailwind-variants';

const datePickerSlots = tv({
    slots: {
        vhidden: 'sr-only',
        root: 'flex w-fit',
        month: 'max-w-[17.375rem]',
        caption: 'relative p-2',
        captionLabel: 'text-coremedia-blue-500 text-center text-sm font-semibold',
        nav: 'absolute inset-0 flex justify-between px-2',
        navButton: [
            'text-coremedia-blue-500 flex aspect-square cursor-pointer items-center justify-center leading-none [&_svg]:size-3.5',
            'enabled:focus-visible:outline-coremedia-blue-600 enabled:focus-visible:outline-1',
        ],
        table: 'table select-none',
        head: 'mt-4 table-header-group',
        headRow: 'table-row',
        headCell: [
            'table-cell size-[1.875rem]',
            'text-coremedia-grey-700 text-center text-sm font-semibold leading-[1.875rem]',
        ],
        tbody: 'table-row-group',
        row: 'table-row',
        day: [
            'table-cell w-[2.1875rem] cursor-pointer bg-transparent align-middle',
            'text-coremedia-grey-700 text-center text-sm leading-[1.875rem]',
            'border-1 rounded-xs border-transparent',
            'enabled:hover:bg-coremedia-grey-200',
            'enabled:focus-visible:outline-coremedia-blue-600 enabled:focus-visible:outline-1',
        ],
        dayToday: 'text-coremedia-blue-500 font-semibold',
        daySelected: '!bg-coremedia-blue-500 hover:bg-coremedia-blue-500 text-white',
        dayOutside: String.raw`rdp-day_outside [&.rdp-day\_outside]:text-coremedia-grey-400 [&.rdp-day\_outside]:hover:text-coremedia-grey-700`,
        weeknumber: 'text-sm font-semibold',
    },
});

export const getDatePickerStyles = (): ClassNames => {
    const {
        vhidden,
        root,
        month,
        caption,
        captionLabel,
        nav,
        navButton,
        table,
        head,
        headRow,
        headCell,
        tbody,
        row,
        day,
        dayToday,
        daySelected,
        dayOutside,
        weeknumber,
    } = datePickerSlots();

    return {
        vhidden: vhidden(),
        root: root(),
        month: month(),
        caption: caption(),
        caption_label: captionLabel(),
        nav: nav(),
        nav_button: navButton(),
        table: table(),
        head: head(),
        head_row: headRow(),
        head_cell: headCell(),
        tbody: tbody(),
        row: row(),
        day: day(),
        day_today: dayToday(),
        day_selected: daySelected(),
        day_outside: dayOutside(),
        weeknumber: weeknumber(),
    };
};
